import head from "lodash/head";
import get from "lodash/get";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { showSuccess } from "../actions/Error";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles, Snackbar, Typography } from "@material-ui/core";
import { useIsMobile } from "../actions/ui";
import { handleSubmitResultReactHookForm } from "../actions/form";
import TopBar from "../views/editprofile/topbar";
import SideBar from "../layout/sidebar";
import { BaseLayout } from "./layout/BaseLayout";
import { FacialRecognitionTechnology } from "./FacialRecognitionTechnology";
import { CustomerProfileFields } from "./CustomerProfileFields";
import { CustomerPasswordFields } from "./CustomerPasswordFields";
import { customerProfileList } from "../v2_actions/customer_profile";
import { isLoggedIn } from "../v2_actions/auth";

const useStyles = makeStyles({
  formHeading: {
    fontSize: "30px",
    fontWeight: 700,
    marginBottom: "25px",
  },
  formGroup: {
    display: "flex",
  },
  contentContainer: { height: "80vh", overflowY: "scroll" },
});

const CustomerProfilePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [showSideBar, setShowSideBar] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const customer_profile_details = useSelector(() =>
    head(customerProfileList.getVisibleObjects()),
  );

  const defaultValues = {
    first_name: get(customer_profile_details, "first_name"),
    last_name: get(customer_profile_details, "last_name"),
    email: get(customer_profile_details, "email"),
    country: get(customer_profile_details, "country"),
    profile_photo: false,
    is_active: 1,
    is_staff: 0,
  };

  const formProps = useForm({ defaultValues: defaultValues });
  const {
    reset,
    handleSubmit,
    register,
    getValues,
    watch,
    control,
    setValue,
    formState: { errors },
  } = formProps;

  const is_watched = watch();

  useEffect(() => {
    dispatch(customerProfileList.invalidateList());
    dispatch(customerProfileList.fetchListIfNeeded());
  }, []);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate(`/customer/signup`);
    }
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [get(customer_profile_details, "id")]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/customer/editprofile/${get(customer_profile_details, "id")}`);
    }
  }, [get(customer_profile_details, "id")]);

  const onSave = (values) => {
    let on_ok = function (json) {
      dispatch(customerProfileList.invalidateList());
      showSuccess("Success", "Profile updated");
    };
    values.id = get(customer_profile_details, "id");
    return dispatch(customerProfileList.saveObject(values)).then((res) =>
      handleSubmitResultReactHookForm({ res, hook_form: formProps, on_ok }),
    );
  };

  return (
    <>
      <BaseLayout>
        <TopBar
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
          width={width}
        />
        <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        <div
          className={
            !showSideBar ? classes.nonMennuWrapper : classes.menuWrapper
          }
          style={{ paddingTop: isMobile ? 65 : 0 }}
        >
          <Snackbar
            open={openSnackBar}
            anchorOrigin={{ vertical: "center", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={() => {
              setOpenSnackBar(false);
            }}
            style={{ height: "calc(100vh - 90px)" }}
          ></Snackbar>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Container>
                <Grid container component="main">
                  <form onSubmit={handleSubmit(onSave)}>
                    <Grid className={classes.formGroup}>
                      <Grid item xs={12} md={6}>
                        <Box mt={1}>
                          <Typography className={classes.formHeading}>
                            Edit Profile Details
                          </Typography>
                          <CustomerProfileFields
                            control={control}
                            formProps={formProps}
                            customerProfileList={customerProfileList}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box mt={1}>
                          <Typography className={classes.formHeading}>
                            Change Password
                          </Typography>
                          <CustomerPasswordFields
                            control={control}
                            formProps={formProps}
                          />
                        </Box>
                        <Box mt={5}>
                          <FacialRecognitionTechnology control={control} />
                        </Box>
                        <Box mt={5}>
                          <Button
                            variant="contained"
                            size="large"
                            mt={10}
                            type="submit"
                          >
                            Update
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
      </BaseLayout>
    </>
  );
};

export default CustomerProfilePage;
