import React from "react";
import styles from "./Typography.module.css";

type HeaderProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export const H1 = (props: HeaderProps) => (
  <h1 {...props} className={styles.h1}>
    {props.children}
  </h1>
);
export const H2 = (props: HeaderProps) => (
  <h2 {...props} className={styles.h2}>
    {props.children}
  </h2>
);
export const H3 = (props: HeaderProps) => (
  <h3 {...props} className={styles.h3}>
    {props.children}
  </h3>
);
export const H4 = (props: HeaderProps) => (
  <h4 {...props} className={styles.h4}>
    {props.children}
  </h4>
);
export const H5 = (props: HeaderProps) => (
  <h5 {...props} className={styles.h5}>
    {props.children}
  </h5>
);

type StrongProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLHeadingElement
>;

export const Strong = (props: StrongProps) => (
  <strong {...props} className={styles.strong}>
    {props.children}
  </strong>
); 