import {
  FaIcon,
  ListItem,
  ListItemLabel,
  ListButton,
} from "../../design-system/designsystem";
import { makeLogger } from "../../utils/o11y/logger";

import { Option } from "../../types/orm";

const logger = makeLogger({
  enabled: true,
  label: "EventsFilterExpander",
  color: "red",
});

const optionLabel = (
  options: Option[],
  value: string | null | undefined,
  expanderType: string,
) => {
  logger.log(expanderType, value, options);
  if (expanderType === "Events" || expanderType === "Photographer") {
    const search = value === "" || value === undefined ? "All" : value;
    return options.find((o) => o.label === search)?.label ?? "";
  }
  return options.find((o) => o.value === value)?.label ?? "";
};

export const EventsFiltersExpander = ({
  label,
  list,
  selectedValue,
  onClick,
}: {
  label: string;
  list: Option[];
  selectedValue: string | null | undefined;
  onClick: () => void;
}) => (
  <ListItem>
    <ListButton fill={true} onClick={onClick}>
      <ListItemLabel>{label}</ListItemLabel>
      <ListItemLabel align="right" size="small">
        {optionLabel(list, selectedValue, label)}
      </ListItemLabel>
    </ListButton>
    <ListButton variant="icon" onClick={onClick}>
      <FaIcon icon="fa-chevron-right" />
    </ListButton>
  </ListItem>
);
