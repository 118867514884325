import React from "react";
import styles from "./NavItem.module.css";

/**
 * NavItem
 * @returns
 */
const NavItem = ({ children }: React.PropsWithChildren<{}>) => {
  return <nav className={styles.navitem}>{children}</nav>;
};

export default NavItem;
