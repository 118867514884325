import React, { MouseEventHandler, ReactNode, useRef, useState } from "react";

import { FaIcon } from "bossfotos-design-system";
import styles from "./DropDown.module.css";
import { useOutsideClick } from "../hooks/useOutsideClick";

export type DropDownOption = {
  label: ReactNode | string;
  value: string;
};

export const DropDown = ({
  selectedValue,
  options,
  renderDropDownOption,
  onSelected,
  disabled,
}: React.PropsWithChildren<{
  selectedValue: string;
  options: DropDownOption[];
  renderDropDownOption?: (option: DropDownOption) => ReactNode;
  onSelected: (options: DropDownOption) => void;
  disabled?: boolean;
}>) => {
  const [showOptions, setShowOptions] = useState(false);
  const [dropDownCoords, setDropDownCoords] = useState<undefined | { x: number, y: number }>()
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: ref,
    handler: () => {
      setShowOptions(false);
    },
  });

  const handleHeaderClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if(showOptions) {
      setDropDownCoords(undefined);
    } else {
      const { left, height } = e.currentTarget.getBoundingClientRect();
      setDropDownCoords({
        x: left,
        y: e.currentTarget.offsetTop + height
      });
    }
    setShowOptions((prev) => !prev);
  };
  const handleOptionClick = (option: DropDownOption) => {
    setShowOptions(false);
    onSelected(option);
  };

  const selectLabel =
    options.find((o) => o.value === selectedValue)?.label ?? "";

  return (
    <div
      className={
        disabled ? styles.dropdownWrapperDisabled : styles.dropdownwrapper
      }
      ref={ref}
    >
      <div onClick={handleHeaderClick} className={styles.dropdownheader}>
        {selectLabel}{" "}
        <FaIcon icon={showOptions ? "fa-chevron-up" : "fa-chevron-down"} />
      </div>
      {showOptions && dropDownCoords && (
        <div className={styles.dropdownoptions} style={{ left: dropDownCoords.x, top: dropDownCoords.y }}>
          {options.map((option) => (
            <div
              key={option.value}
              className={
                selectedValue === option.value
                  ? styles.dropdownoptiondisabled
                  : ""
              }
              onClick={() => handleOptionClick(option)}
            >
              {renderDropDownOption
                ? renderDropDownOption(option)
                : option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
