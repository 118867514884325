import { ItemList } from '../orm'

class CustomerFacialRecognition extends ItemList {

  getEntityKey() {
    return 'customer/facial_recognition';
  }

  indexFaces(photograph) {
    return this.saveNewObject({
      action: 'index',
      photograph_id: photograph.id
    })
  }
  
  indexForCustomer(fileInfoId, customerEmail) {
    return this.saveNewObject({
      action: 'index_customer',
      file_info_id: fileInfoId,
      customer_email: customerEmail
    })
  }

  searchForCustomer(gallery_id, customer_face_token) {
    return this.saveNewObject({
      action: 'search_customer',
      gallery_id,
      customer_face_token
    })
  }

  setCustomerFacialRecognitionFaceToken(faceToken) {
    sessionStorage.setItem('customer_facial_recognition_face_token', faceToken)
  }

  getCustomerFacialRecognitionFaceToken(faceToken) {
    sessionStorage.getItem('customer_facial_recognition_face_token')
  }
}

export const customerFacialRecognition = new CustomerFacialRecognition('customer_facial_recognition__default');
