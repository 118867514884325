import { useEffect } from "react";
import { clearActiveTrolleyCookieId } from "../v2_actions/trolley_cookie";

/**
 * Calling this removes persisted trolley on browse exit
 */
export const useBrowserCloseEffect = () => {
  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      clearActiveTrolleyCookieId();
    });
  }, []);
};
