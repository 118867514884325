import get from "lodash/get";
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { http } from "../lib";
import cookie from "react-cookies";
import { setMapState } from "./map";
import { showError } from "./error";
import { guessCountryCode } from "../utils/detectCountryCode";
import { countryList } from "./country";
import { trolleyList } from "./trolley";
import { authenticatedTrolleyList } from "./authenticated_trolley";
import { sentryApi } from "../utils/o11y/sentryApi";

export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const CLEAR_AUTH_TOKEN = "CLEAR_AUTH_TOKEN";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const ANNOUNCE_LOGGING_IN_USER = "ANNOUNCE_LOGGING_IN_USER";
export const ANNOUNCE_USER_NOT_LOGGED_IN = "ANNOUNCE_USER_NOT_LOGGED_IN";
export const ANNOUNCE_SENDING_TOKEN_EMAIL = "ANNOUNCE_SENDING_TOKEN_EMAIL";
export const ANNOUNCE_TOKEN_EMAIL_SENT = "ANNOUNCE_TOKEN_EMAIL_SENT";
export const ANNOUNCE_TOKEN_EMAIL_NOT_SENT = "ANNOUNCE_TOKEN_EMAIL_NOT_SENT";
export const ANNOUNCE_SETTING_PASSWORD = "ANNOUNCE_SETTING_PASSWORD";
export const ANNOUNCE_PASSWORD_SET = "ANNOUNCE_PASSWORD_SET";
export const ANNOUNCE_PASSWORD_NOT_SET = "ANNOUNCE_PASSWORD_NOT_SET";
export const ANNOUNCE_USER_LOGGED_OUT = "ANNOUNCE_USER_LOGGED_OUT";
export const ANNOUNCE_UPDATING_USER = "ANNOUNCE_UPDATING_USER";
export const ANNOUNCE_USER_UPDATED = "ANNOUNCE_USER_UPDATED";
export const ANNOUNCE_USER_NOT_UPDATED = "ANNOUNCE_USER_NOT_UPDATED";

export const setShowReteralModal = (showReteralModal) => ({
  type: "USER/SHOWRETERALMODAL",
  showReteralModal,
});
export const userInfo = (userInfo) => ({
  type: "USER/USERINFO",
  userInfo,
});

export function announceUserNotLoggedIn() {
  return {
    type: ANNOUNCE_USER_NOT_LOGGED_IN,
  };
}

export function announceUserLoggedIn(token, user) {
  return async (dispatch, getState) => {
    setAuthToken(token);
    dispatch(refreshLoggedInUserCookieFromUser(user));
    dispatch({
      type: USER_LOGGED_IN,
      token,
      user,
    });
  };
}

export function announceLoggingInUser() {
  return {
    type: ANNOUNCE_LOGGING_IN_USER,
  };
}

export function announceSendingTokenEmail() {
  return {
    type: ANNOUNCE_SENDING_TOKEN_EMAIL,
  };
}

export function announceTokenEmailSent() {
  return {
    type: ANNOUNCE_TOKEN_EMAIL_SENT,
  };
}

export function announceTokenEmailNotSent() {
  return {
    type: ANNOUNCE_TOKEN_EMAIL_NOT_SENT,
  };
}

export function announceSettingPassword() {
  return {
    type: ANNOUNCE_SETTING_PASSWORD,
  };
}

export function announcePasswordSet(user) {
  return async (dispatch, getState) => {
    cookie.remove("user", { path: "/" });
    dispatch(refreshLoggedInUserCookieFromUser(user));
    dispatch({
      type: ANNOUNCE_PASSWORD_SET,
    });
  };
}

export function announcePasswordNotSet() {
  return {
    type: ANNOUNCE_PASSWORD_NOT_SET,
  };
}

export function announceUserLoggedOut() {
  return {
    type: ANNOUNCE_USER_LOGGED_OUT,
  };
}

export function announceUpdatingUser() {
  return {
    type: ANNOUNCE_UPDATING_USER,
  };
}

export function announceUserUpdated(user) {
  cookie.remove("user", { path: "/" });
  cookie.save("user", user, { path: "/" });
  return {
    type: ANNOUNCE_USER_UPDATED,
  };
}

export function announceUserNotUpdated() {
  return {
    type: ANNOUNCE_USER_NOT_UPDATED,
  };
}

export const setErrorMessage = (errorMessage) => ({
  type: "USER/ERRORMESSAGE",
  errorMessage,
});

export function login(email, password) {
  return async (dispatch, getState) => {
    try {
      dispatch(announceLoggingInUser());
      let [json, success] = await http.post(getState(), "login/login", {
        email: email,
        password: password,
      });
      if (success.status === 200 && has(json, "token")) {
        dispatch(announceUserLoggedIn(json.token, json.user || {}));
      } else {
        dispatch(announceUserNotLoggedIn());
        showError("Failed", "Failed to login with your credentials");
      }
      if (!json.token) {
        showError("Failed", json.message);
      }
    } catch (e) {
      showError("Failed", e.message);
    }
  };
}

export function signUp(
  data,
  navigate,
  setOpenSnackBar,
  setErrorMessage,
  trolley_id_from_url,
) {
  return async (dispatch, getState) => {
    try {
      sentryApi.breadcrumb({
        category: "signup",
        level: "info",
        message: "start",
        data: {
          email: data.email,
          cookies: Object.keys(cookie?.loadAll()),
          trolley_id_from_url,
        },
      });

      dispatch(announceLoggingInUser());
      let [json, success] = await http.post(getState(), "signup/signup", {
        data,
      });
      // TODO:
      // if success.status == 409 then the user already exists
      // use this as a hook to display the error message
      // if not 200 or 409, something is broken and we should log with sentry
      if (success.status === 200 && has(json, "token")) {
        const userInfoData = json.user;
        const trolley = trolleyList.getTrolley();

        dispatch(announceUserLoggedIn(json.token, json.user));
        dispatch(authenticatedTrolleyList.trySetTrolleyCustomer());
        dispatch(setShowReteralModal(true));
        dispatch(userInfo(userInfoData));

        if (
          size(get(trolley, "trolley_photographs")) > 0 ||
          trolley_id_from_url
        ) {
          navigate(
            `/customer/shopping/${
              trolley_id_from_url || get(trolley, "id")
            }/?showPrintAndFrameOffer=true`,
          );
        } else {
          navigate(`/customer/events`);
        }
      } else {
        sentryApi.error("signup_http_error_non_200", {
          httpstatuscode: success.status,
        });
        dispatch(announceUserNotLoggedIn());
      }
      if (!json.token) {
        setOpenSnackBar(true);
        setErrorMessage(
          "This email address has already been used. The same email cannot be used to register as a  customer and a photographer",
        );
        sentryApi.error("signup_error_email_in_use", {});
      } else {
        sentryApi.breadcrumb({
          category: "signup",
          level: "info",
          message: "success",
          data: {},
        });
      }
    } catch (e) {
      setOpenSnackBar(true);
      setErrorMessage(`Failed: ${e}`);
      console.error(e.message);
      sentryApi.error("signup_error", { error: "unknown", e });
    }
  };
}

export function auto_login(user_id, otp) {
  return async (dispatch, getState) => {
    try {
      dispatch(announceLoggingInUser());
      let [json, success] = await http.post(getState(), "login/auto_login", {
        user: user_id,
        otp: otp,
      });
      if (success.status === 200 && has(json, "token")) {
        dispatch(announceUserLoggedIn(json.token, json.user || {}));
      } else {
        dispatch(announceUserNotLoggedIn());
        return { errors: "Failed to login" };
      }
      if (!json.success) {
        return { errors: json.message };
      }
      return json;
    } catch (e) {
      return { errors: e };
    }
  };
}

export function isLoggedIn() {
  const token = authToken();
  return !isEmpty(token) && size(token) > 0;
}

export function authToken() {
  return cookie.load("apitoken") || window.localStorage.getItem("token");
}

export function setAuthToken(token) {
  return cookie.save("apitoken", token, { path: "/" });
}

export function clearAuthentication() {
  cookie.remove("trolley_id", { path: "/" });
  cookie.remove("apitoken", { path: "/" });
  cookie.remove("user", { path: "/" });
  sentryApi.breadcrumb({
    category: "logout",
    level: "info",
    message: "logout",
    data: {},
  });
  return {
    type: CLEAR_AUTH_TOKEN,
  };
}

export function sendTokenEmail(user) {
  return async (dispatch, getState) => {
    dispatch(announceSendingTokenEmail());
    let success;
    [success] = await http.post(getState(), "user/send_otp_email", {
      user: user,
    });
    if (success.status === 200) {
      dispatch(announceTokenEmailSent());
    } else {
      dispatch(announceTokenEmailNotSent());
    }
  };
}

export function sendForgotPasswordEmail(email) {
  return async (dispatch, getState) => {
    dispatch(announceSendingTokenEmail());
    let success;
    [success] = await http.post(getState(), "login/send_otp_email", {
      email: email,
    });
    if (success.status === 200) {
      dispatch(announceTokenEmailSent());
    } else {
      dispatch(announceTokenEmailNotSent());
    }
  };
}

export function setPassword(password, on_done) {
  return async (dispatch, getState) => {
    dispatch(announceSettingPassword());
    let [json, success] = await http.post(
      getState(),
      "login/login/set_password",
      { password: password },
    );
    if (success.status === 200) {
      dispatch(announcePasswordSet(json.user));
      on_done();
    } else {
      dispatch(announcePasswordNotSet());
      showError("Unable to set password");
    }
  };
}

export function setLanguage(language_code, on_done) {
  return async (dispatch, getState) => {
    let [json, success] = await http.post(getState(), "user/set_language", {
      language_code: language_code,
    });
    if (
      success.status === 200 &&
      has(json, "status") &&
      json["status"] === "success"
    ) {
      on_done();
    } else {
      showError("Unable to set language");
    }
  };
}

export function updateUser(values, on_done) {
  return async (dispatch, getState) => {
    dispatch(announceUpdatingUser());
    let [json, success] = await http.post(
      getState(),
      "user/update_user",
      values,
    );
    if (
      success.status === 200 &&
      has(json, "status") &&
      json["status"] === "success"
    ) {
      dispatch(announceUserUpdated(json.user));
      on_done();
    } else {
      dispatch(announceUserNotUpdated());
      showError("Unable to update user");
    }
  };
}

export function refreshLoggedInUserCookieFromUser(user) {
  return async (dispatch, getState) => {
    const values = {
      id: user.id,
      has_usable_password: user.has_usable_password,
      country: user.country,
      // currency_code: user.currency_code, # note for awi: consider removing completeld
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      is_superuser: user.is_superuser,
      is_staff: user.is_staff,
    };
    cookie.save("user", values, { path: "/" });
    if (user.preferred_lat && user.preferred_lng) {
      setStoredLatLng({ lat: user.preferred_lat, lng: user.preferred_lng });
    } else {
      guessCountryCode(({ country_code, position }) => {
        setStoredLatLng(position);
        if (position.lat && position.lng) {
          setMapState(position);
        }
      });
    }
  };
}

export function loggedInUser() {
  return cookie.load("user");
}

export function getLoggedInUserId() {
  return get(loggedInUser(), "id");
}

export function loggedInUserLastName() {
  return get(loggedInUser(), "last_name");
}

export function getLoggedInUserEmail() {
  return get(loggedInUser(), "email");
}

export const getUserStoredCountryID = () => {
  const country_id = get(loggedInUser(), "country");
  return country_id;
};

export const getUserStoredCountryCode = () => {
  const country_id = get(loggedInUser(), "country");
  return countryList.getCountryCode(country_id);
};

export const getUserStoredCurrencyCode = () => {
  const country_id = get(loggedInUser(), "country");
  return countryList.getCurrencyCode(country_id);
};

export const getUserStoredCurrencySymbol = () => {
  const country_id = get(loggedInUser(), "country");
  return countryList.getCurrencySymbol(country_id);
};

export const getLoggedInUserDisplayName = () => {
  return get(loggedInUser(), "first_name");
};

export const getStoredLatLng = () => {
  return {
    lat: parseFloat(window.localStorage.getItem("lat")),
    lng: parseFloat(window.localStorage.getItem("lng")),
  };
};

export const setStoredLatLng = ({ lat, lng }) => {
  if (lat && lng) {
    window.localStorage.setItem("lat", lat);
    window.localStorage.setItem("lng", lng);
  }
};
