import get from "lodash/get";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { CurrencyValue } from "./CurrencyValue";
import { useIsMobile } from "../actions/ui";
import { trolleyPhotographList } from "../v2_actions/trolley_photograph";

const useStyles = makeStyles((theme) => ({
  promptWrapper: {
    "& div.MuiPaper-root": {
      height: 393,
      padding: "8px",
      borderRadius: "19px",
      border: "solid 1px #000",
      [theme.breakpoints.down("sm")]: {
        margin: 5,
        height: 410,
      },
    },
  },
  promptButtonText: {
    fontWeight: 600,
  },
  pricesWrapper: {
    position: "relative",
    top: 61,
    [theme.breakpoints.down("sm")]: {
      top: 20,
    },
  },
  promptButtonWrapper: {
    position: "relative",
    top: 105,
    [theme.breakpoints.down("sm")]: {
      top: 55,
    },
  },
  promptButton: {
    height: 57,
    width: 176,
    borderRadius: "10px !important",
  },
  promptTitle: {
    fontSize: 18,
    color: "#000",
    fontWeight: 800,
    margin: "auto",
    padding: 0,
    top: 41,
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      top: 15,
    },
  },
  promptInfo: {
    position: "relative",
    top: 110,
    fontSize: 18,
    color: "#000",
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      top: 60,
      fontSize: 15,
    },
  },
  heading: {
    margin: 0,
    fontSize: 18,
    color: "#000",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      fontSize: 15,
    },
  },
  currencyPrice: {
    margin: 0,
    fontSize: 18,
    color: "#000",
    fontWeight: 800,
    "& span": {
      margin: 0,
      fontSize: 18,
      color: "#000",
      fontWeight: 800,
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginLeft: 0,
    },
  },
  priceDesc: {
    margin: 0,
    fontSize: 15,
    color: "#000",
    fontWeight: 400,
  },
}));

const Item = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  color: "blue",
}));

const HIGH_RES = "high-res";
const LOW_RES = "low-res";

export default function LowHighResPhotoPrompt({
  trolley,
  gallery,
  photo_id,
  handleClose,
  isUpdatingTrolley,
  trolleyPhotograph,
}) {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const galleryBasePrice = get(gallery, ["gallery_price", "base_price"]);
  const galleryLowResPrice = get(gallery, ["gallery_price", "low_res_price"]);
  const discountedGalleryBasePrice = get(gallery, [
    "gallery_price",
    "discounted_base_price_using_discount1_percentage",
  ]);

  const handleHighResSelection = () => {
    dispatch(
      trolleyPhotographList.saveObject({
        quality: HIGH_RES,
        trolley: get(trolley, "id"),
        photo_delivery_type: "digital_only",
        copy_to_remaining_photographs: false,
        id: get(trolleyPhotograph, "id"),
      }),
    );
    handleClose();
  };

  const handleLowResSelection = () => {
    dispatch(
      trolleyPhotographList.saveObject({
        quality: LOW_RES,
        trolley: get(trolley, "id"),
        copy_to_remaining_photographs: false,
        photo_delivery_type: "low_res_digital_only",
        id: get(trolleyPhotograph, "id"),
      }),
    );
    handleClose();
  };

  const renderHighResPrice = () => {
    return (
      <div>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0, md: 8 }}
          justifyContent="flex-start"
        >
          <p className={classes.heading}>High Res</p>
          <p className={classes.currencyPrice}>
            <CurrencyValue
              value={galleryBasePrice}
              country={get(gallery, ["gallery_price", "country"])}
            />{" "}
            for one {isMobile && <br />} (
            <CurrencyValue
              value={discountedGalleryBasePrice}
              country={get(gallery, ["gallery_price", "country"])}
            />{" "}
            thereafter)
          </p>
        </Stack>
        <p className={classes.priceDesc}>(Full Resolution JPG. No Branding)</p>
      </div>
    );
  };

  const renderLowResPrice = () => {
    return (
      <div>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={{ xs: 0, md: 8 }}
        >
          <p className={classes.heading}>Low Res</p>
          <p className={classes.currencyPrice}>
            <CurrencyValue
              value={galleryLowResPrice}
              country={get(gallery, ["gallery_price", "country"])}
            />{" "}
            each
          </p>
        </Stack>
        <p className={classes.priceDesc}>
          (Suitable for Social Media Only. May contain Logos)
        </p>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.promptWrapper}
      >
        <p className={classes.promptTitle}>
          Select High Resolution or Low Resolution Photos
        </p>
        <DialogContent>
          <Stack
            direction="column"
            className={classes.pricesWrapper}
            spacing={{ xs: 3, sm: 4, md: 3 }}
          >
            {renderHighResPrice()}
            {renderLowResPrice()}
          </Stack>
          <Stack
            alignItems="center"
            className={classes.promptButtonWrapper}
            justifyContent="space-between"
            spacing={{ xs: 1, sm: 2, md: 4 }}
            direction={{ xs: "row", sm: "row" }}
          >
            <Button
              size="large"
              variant="contained"
              disabled={isUpdatingTrolley}
              className={classes.promptButton}
              onClick={handleHighResSelection}
            >
              <p className={classes.promptButtonText}>High Res</p>
            </Button>
            <Button
              size="large"
              variant="contained"
              disabled={isUpdatingTrolley}
              className={classes.promptButton}
              onClick={handleLowResSelection}
            >
              <p className={classes.promptButtonText}>Low Res</p>
            </Button>
          </Stack>
          <p className={classes.promptInfo}>
            You may edit your selection later on in the checkout
          </p>
        </DialogContent>
      </Dialog>
    </div>
  );
}
