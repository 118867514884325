import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { trolleyPhotographList } from "../v2_actions/trolley_photograph";
import { authenticatedTrolleyList } from "../v2_actions/authenticated_trolley";
import { TrolleyLayout } from "./layout/TrolleyLayout";
import { TrolleyPhotographForm } from "./trolley/TrolleyPhotographForm";
import { TrolleyPhotographCarousel } from "./TrolleyPhotographCarousel";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  photograph_image: {
    maxHeight: "100vh",
  },
  photograph_image_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const TrolleyPhotographPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activePrintTypeId, setActivePrintTypeId] = useState(null);
  const [activePhotoDeliveryType, setActivePhotoDeliveryType] = useState(null);
  const { trolley_photograph_id } = useParams();
  const authenticated_trolley = useSelector(() =>
    authenticatedTrolleyList.getTrolley(),
  );
  const active_trolley_photograph = useSelector(() =>
    trolleyPhotographList.getObject(trolley_photograph_id),
  );
  const photograph = get(active_trolley_photograph, "photograph");

  useEffect(() => {
    dispatch(trolleyPhotographList.ensureObjectLoaded(trolley_photograph_id));
  }, [trolley_photograph_id]);

  const renderTrolleyForm = () => {
    return (
      <TrolleyPhotographForm
        trolley={authenticated_trolley}
        active_trolley_photograph={active_trolley_photograph}
        onPrintTypeIdChanged={setActivePrintTypeId}
        onPhotoDeliveryTypeChanged={setActivePhotoDeliveryType}
      />
    );
  };

  const renderCarousel = () => {
    return (
      <TrolleyPhotographCarousel
        photograph={photograph}
        isCheckoutPage
        print_type_id={activePrintTypeId}
        photo_delivery_type={activePhotoDeliveryType}
      />
    );
  };

  return (
    <>
      <TrolleyLayout
        renderTrolleyForm={renderTrolleyForm}
        renderCarousel={renderCarousel}
      />
    </>
  );
};

export default TrolleyPhotographPage;
