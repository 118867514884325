// eslint-disable-next-line
import responsive from "../../mobile";

const styles = (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  menuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    marginTop: 100,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 100,
      paddingLeft: 300,
    },
  },
  nonMennuWrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  innerLabelWrapper: {
    display: "flex",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    height: "97vh", // this leaves a gap at the bottom on mobile. Can set to 100vh to use full screen height
    overflow: "hidden",
    overflowX: "hidden",
    overflowY: "hidden",
    [theme.breakpoints.up("sm")]: {
      marginTop: 80,
      height: "calc(100vh - 80px)",
    },
  },
  content: {
    flex: "1 1 auto",
    overflowX: "hidden",
    '&:focus-visible':{
      outline: "none",
    }
  },
  avatar: {
    width: 45,
    height: 45,
  },
  text: {
    fontSize: 16,
    margin: "auto",
    color: "white",
  },
  lowHighResText: {
    fontSize: 16,
    margin: "auto",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  priceBoxWrapper: {
    margin: "0px auto",
  },
  button: {
    height: 40,
    textTransform: "capitalize",
    color: "#0c59cf",
  },
  clearFiltersButton: {
    height: 30,
    margin: "auto",
  },
  clearFiltersButtonText: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  card: {
    padding: 20,
    color: "#0c59cf",
    border: "1px solid black",
    height: "calc(100vh - 350px)",
  },
  mobilecard: {
    padding: 20,
    color: "#0c59cf",
  },
  textField: {
    width: " 50px !important",
    height: 50,
    paddingLeft: 5,
    backgroundColor: "#606060",
    borderRadius: "50%",
    color: "white",
    fontWeight: "500",
    fontSize: 16,
    border: "1px solid #606060",
  },
  carousel: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
  },
  carouselbutton: {
    backgroundColor: "white",
    border: "1px solid #0c59cf",
    borderRadius: 20,
    width: "100%",
    color: "#0c59cf",
    textTransform: "capitalize",
  },
  greybtn: {
    width: "100%",
    textTransform: "capitalize",
    borderRadius: 5,
    border: "1px solid black",
  },
  mobilgreybtn: {
    width: "50%",
    textTransform: "capitalize",
    borderRadius: 5,
    border: "1px solid black",
  },
  imgInnerCard: {
    position: "absolute",
    top: 5,
    left: 5,
    borderRadius: 20,
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    width: "97%",
  },
  imgInnerSmallCard: {
    position: "absolute",
    top: 5,
    left: 5,
    borderRadius: 20,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "97%",
  },
  galleryLowResPriceBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(96 96 96 / 50%)",
    width: 130,
    borderRadius: 20,
    height: 40,
    fontWeight: "500",
    fontSize: 16,
  },
  priceBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(96 96 96 / 50%)",
    width: 100,
    borderRadius: 20,
    height: 40,
    fontWeight: "500",
    fontSize: 16,
  },
  shareBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgb(96 96 96 / 50%)",
    width: 120,
    borderRadius: 20,
    height: 40,
    padding: 5,
    fontWeight: "500",
    fontSize: 16,
  },
  shopIcon: {
    width: 40,
    height: 40,
    "&:active svg": {
      color: `#ff2727 !important`,
    },
    backgroundColor: "rgb(96 96 96 / 50%)",
    "&:hover": {
      backgroundColor: "#212121",
    },
  },
  icon: {
    color: "white",
    cursor: "pointer",
  },
  smallbox: {
    backgroundColor: "#212121",
    color: "white",
    padding: 5,
  },
  popover: {
    pointerEvents: "none",
  },
  popoverpaper: {
    padding: theme.spacing(1),
  },
  typography: {
    cursor: "pointer",
  },
  labelIsSelectingLoader: {
    position: "absolute",
    width: "20px",
    height: "20px",
    right: "20px",
    top: "0px",
  },
  photographTagsWrapper: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 16px",
    },
    color: "#929292",
  },
  photographTags: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  photographerBio: {
    color: "#929292",
  },
  photographInfoDescription: {
    color: "#929292",
  },
  photographerAvatar: {
    width: "95px",
    height: "95px",
    marginRight: "30px",
  },
  photoArtHeaders: {
    color: "#003748",
    padding: "0px 16px",
    marginBottom: 0,
  },
  loadMoreButtonWrapper: {
    textAlign: "center",
  },
  loadMoreButton: {
    margin: "60px 0px 10px",
  },
  morePhotoArtCard: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
    margin: "auto",
    [`@media only screen and (max-width:${responsive.getMobMaxWidthPixels()})`]:
      {
        gridTemplateColumns: "repeat(1, 1fr)",
        marginLeft: "0px",
        width: "100%",
        height: "auto",
        marginTop: "55px",
        overflowY: "hidden",
      },
  },
  scrollingButtons: {
    position: "absolute",
    marginTop: "30%",
    width: "100%",
    zIndex: 9,
  },
  scrollingWrapper: {
    overflowX: "scroll",
    overflowY: "hidden",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
  },
  scrollingCard: {
    display: "inline-block",
    margin: "0px 20px",
  },
  scrollButton: {
    cursor: "pointer",
  },
  photoArtPhotosLeftPane: {
    display: "none",
  },
  primaryPhotoArtPhotograph: {
    width: "100%",
  },
  photoArtPageCarouselStyle: {
    overflowX: "scroll",
    overflowY: "hidden",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    "& div": {
      display: "inline-block",
      marginRight: "20px",
    },
  },
  photoArtPhotographerName: {
    float: "right",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  photoArtCameraIcon: {
    fontSize: 25,
    marginRight: 5,
    color: "#7a7a7a",
  },
  mobilePhotoArtDetailWrapper: {
    padding: "0 10px",
  },
  mobilePhotoIsFavorite: {
    top: 3,
    right: "25%",
    position: "relative",
  },
  mobilePhotoArtAddToTrolleyButton: {
    left: 10,
    float: "right",
    position: "relative",
  },
  mobilePhotoArtSessionName: {
    height: 35,
  },
  mobilePhotoArtSessionNameWrapper: {
    height: 35,
  },
  mobilePhotoArtPriceWrapper: {
    height: 35,
  },
  mobilePhotoArtAddToTrolleyWrapper: {
    height: 35,
  },
  sidebarForGalleryPhotographDesktop: {
    zIndex: 99,
    width: 260,
    float: "left",
    height: "100vh",
    display: "block",
    overflow: "hidden",
    position: "sticky",
    background: "#f4f4f4",
    padding: "80px 0px 15px 30px",
  },
  sidebarForGalleryPhotographDesktopSpacer: {
    left: -34,
    height: 25,
    bottom: 25,
    width: "120%",
    background: "#fff",
    position: "relative",
  },
  sidebarForGalleryPhotographDesktopHeading: {
    fontSize: 16,
    fontWeight: 600,
  },
  mobileFilterOptionsForGalleryPhotographs: {
    "& div": {
      display: "block",
    },
    "& div > svg": {
      position: "relative",
      top: 10,
      fontSize: 40,
    },
  },
  accordianHeading: {
    margin: 0,
    fontSize: 16,
    width: "150px",
  },
  accordion: {
    overflow: "auto",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
  },
  mobiClearFiltersButton: {
    "& *": {
      color: "#000",
      fontSize: 12,
      textTransform: "Capitalize",
      position: "relative",
      right: 0,
      cursor: "pointer",
    },
  },
  photographTimeTakenWrapper: {
    left: 5,
    bottom: 5,
    padding: 10,
    width: "97%",
    display: "flex",
    borderRadius: 20,
    position: "absolute",
    justifyContent: "space-between",
  },
  photographTimeTakenBox: {
    left: 10,
    bottom: 10,
    height: 40,
    width: 100,
    fontSize: 16,
    fontWeight: 500,
    display: "flex",
    borderRadius: 20,
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: "rgb(96 96 96 / 50%)",
  },
  galleryPhotographsLayoutContainerGrid: {
    marginTop: "0px!important", // prevent photo grid overlapping mobile filter buttons
    "& div:first-child": {
      paddingTop:"16px!important" // tweak padding on first photo in mobile
    }
  }
});

export default styles;
