import * as Sentry from "@sentry/browser";

import cookie from "react-cookies";

const error = (
  /** specify a meaningful error code that will make sense in Sentry */
  errorCode: string,
  /** append any data to help diagnose faults. DO NOT send PII or large data */
  debuginfo: Record<string, any>,
) => {
  Sentry.captureException(new Error(`bferror ${errorCode}`), (scope) => {
    scope.setUser({ id: cookie.load("user")?.id });
    scope.setTag("bfevent", "bug");
    scope.setContext("debuginfo", debuginfo);
    return scope;
  });
};

const breadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
  Sentry.addBreadcrumb(breadcrumb);
};

export const sentryApi = {
  error,
  breadcrumb,
};
