// eslint-disable-next-line
export default (theme) => ({
  root: {
    height: 100,
    [theme.breakpoints.down('sm')]: {
      bottom: 5,
      height: 130,
      position: 'relative'
    },
  },
    popover: {
        pointerEvents: "none",
    },
    popoverpaper: {
        padding: theme.spacing(1),
    },
    iconbtn: {
        width: 20,
        height: 20,
        color: "black",
    },
    backbtn: {
      width: 30,
      height: 30,
      padding: 0,
      color: "black",
    },
    middleiconbtn: {
        width: 30,
        height: 30,
        color: "black",
    },
    text: {
        color: "#606060",
    },
    box: {
        borderRadius: 20,
    },
    card: {
        display: "flex",
        alignItems: "center",
        padding: 8,
        color: "black",
        background: "white",
        border: "1px solid #000000",
        borderRadius: 10,
        fontSize: 20,
    },
    typography:{
      fontSize: 12,
      cursor: 'pointer',
    },
    photographerPricingTypography: {
      top: 3,
      fontSize: 12,
      cursor: 'pointer',
      position: 'relative',
    },
    photographerNameTypography: {
      fontSize: 12,
      
    },
    photo_art_header: {
      fontSize: 18,
    },
    mobiletypography:{
      right: 10,
      fontSize:16,
      cursor:'pointer',
      textAlign:'center',
      position: 'relative'
    },
    button: {
        height: 40,
        textTransform: "capitalize",
        width: "100%",
    },
    mobilbtn: {
        height: 35,
        textTransform: "capitalize",
        width: "100%",
    },
    purchasedbutton: {
        height: 40,
        textTransform: "capitalize",
        width: "100%",
    },
    buttongroup: {
        width: "50%",
        height: 38,
    },
    paper: {
        position: "absolute",
        background:"white",
        width: 500,
        border: "2px solid #0c59cf !important",
        color: "#0c59cf",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mobilePaper:{
        position: "absolute",
        background:"white",
        width: "100%",
        border: "2px solid #0c59cf !important",
        color: "#0c59cf",
        boxShadow: theme.shadows[5],
        padding: 5,
    },
  popover: {
    pointerEvents: 'none',
  },
  popoverpaper: {
    padding: theme.spacing(1),
  },
  iconbtn: {
    width: 20,
    height: 20,
    color: 'black',
  },
  backbtn: {
    width: 30,
    height: 30,
    padding: 0,
    color: 'black',
  },
  middleiconbtn: {
    width: 30,
    height: 30,
    color: 'black',
  },
  text: {
    color: '#606060',
  },
  box: {
    borderRadius: 20,
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    color: 'black',
    background: 'white',
    border: '1px solid #000000',
    borderRadius: 10,
    fontSize: 20,
  },
  galleryNameTypography: {
    top: 0,
    marginRight: 25,
    cursor:'pointer',
    overflow: 'hidden',
    fontSize: '17px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    position: 'relative',
    textOverflow: 'ellipsis'
  },
  pricingTypography: {
    fontSize: 12,
    fontWeight: 400
  },
  photographerNameTypography: {
    margin: 0,
    bottom: 1,
    fontSize: 12,
    fontWeight: 400,
    position: 'relative',
  },
  galleryNamePopover: {
    margin: '15px 25px'
  },
  typography:{
    cursor:'pointer',
  },
  photo_art_header: {
    fontSize: 18,
  },
  button: {
    height: 40,
    textTransform: 'capitalize',
    width: '100%',
  },
  mobilbtn: {
    height: 35,
    textTransform: 'capitalize',
    width: '100%',
  },
  purchasedbutton: {
    height: 40,
    textTransform: 'capitalize',
    width: '100%',
  },
  buttongroup: {
    width: '50%',
    height: 38,
  },
  paper: {
    position: 'absolute',
    background:'white',
    width: 500,
    border: '2px solid #0c59cf !important',
    color: '#0c59cf',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  mobilePaper:{
    position: 'absolute',
    background:'white',
    width: '100%',
    border: '2px solid #0c59cf !important',
    color: '#0c59cf',
    boxShadow: theme.shadows[5],
    padding: 5,
  },

    number_of_photos_tooltip: {
        fontSize: "10px",
        position: "absolute",
        right: "0px",
        top: "0px"
    },
    checkoutBtn: {
        width: "150px",
        fontSize: "14px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 10,   
            marginTop: 5,
        },
    },
    photoArtRootNavBar: {
        width: "100%",
        alignItems: "baseline"
    },
    photoArtPricingHeading: {
        margin: 0
    },
    photoArtPhotographerNameWrapper: {
        fontSize: 12,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center'
    },
    photoArtPhotographerName: {
        margin: 0
    },
    photoArtBackBtn: {
        width: 30,
        height: 30,
        padding: 0,
        color: "black",
        paddingLeft: "0px",
        position: "relative",
        bottom: 1
    },
    photoArtCameraIcon: {
        fontSize: 25, 
        marginRight: 5, 
        color: "#7a7a7a" 
    },
  photoArtRootNavBar: {
    width: '100%',
    alignItems: 'baseline'
  },
  photoArtPricingHeading: {
    margin: 0
  },
  photoArtPhotographerNameWrapper: {
    
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  photoArtPhotographerName: {
    margin: 0
  },
  photoArtBackBtn: {
    width: 30,
    height: 30,
    padding: 0,
    bottom: 2,
    color: '#606060',
    paddingLeft: '0px',
    position: 'relative',
  },
  photoArtCameraIcon: {
    fontSize: 25, 
    marginRight: 5, 
    color: '#7a7a7a' 
  },
  photoArtCheckoutBtn: {
    marginTop: 10,
  },
  mobilePhotoArtTopBar: {
    width: '100%',
    '& div': {
      margin: 'auto'
    }
  },
  mobilePhotoArtTopBarBorder: {
    top: 10,
    position: 'relative',
    borderBottom: '#aaa solid 1px',
  },
  mobilePhotoArtTopBarCheckoutButton: {
    margin: 'auto !important'
  },
  facialRecognitionIcon: {
    fontSize: 25,
    marginRight: 5,
    color: '#7a7a7a',
    cursor: 'pointer'
  },
  facialRecognitionIconActive: {
    color: '#0c59cf' 
  },
  clearFacialRecognitionMatch: {
    cursor: 'pointer'
  }
})
