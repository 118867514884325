import React, { CSSProperties } from "react";

const variantStyles: Record<string, CSSProperties> = {
  default: { fontSize: "inherit" },
  nav: { fontSize: "25px" },
  "button-left": { fontSize: "inherit", marginRight: "10px" },
};

type IconVariant = keyof typeof variantStyles;

/**
 * Wrapper for font-awesome icon
 * @returns
 */
const FaIcon = ({
  icon,
  variant = "default",
  color = "default",
}: {
  icon: string;
  variant?: IconVariant;
  color?: "default" | "primary" | "secondary";
}) => {
  let style = { ...variantStyles[variant] };
  if (color == "primary") {
    style.color = "var(--button-bg-color)";
  }
  if (color == "secondary") {
    style.color = "var(--button-bg-color)";
    style.opacity = "0.5";
  }
  return <i className={`fa ${icon}`} style={style} />;
};

export default FaIcon;
