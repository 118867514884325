import React, { CSSProperties } from "react";
import styles from "./NavButton.module.css";

const variantsStyles: Record<string, CSSProperties> = {
  default: {},
  input: {
    color: "var(--input-icon-color)",
  },
  expander: {
    height: "var(--expander-height)",
  },
};

type Variants = keyof typeof variantsStyles;

type NavButtonProps = {
  variant?: Variants;
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "className"
>;

/**
 * Nav Button
 * @returns
 */
const NavButton = ({ variant, children, ...props }: NavButtonProps) => {
  return (
    <button
      {...props}
      className={styles.navbutton}
      style={{ ...variantsStyles[variant ?? "default"], ...props.style }}
    >
      {children}
    </button>
  );
};

export default NavButton;
