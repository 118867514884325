import get from "lodash/get";

import "react-notifications/lib/notifications.css";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Global, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import { CheckoutButton } from "../../../v2_components/CheckoutButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PriceModal from "./pricemodal";
import PropTypes from "prop-types";
import { basePhotographList } from "../../../v2_actions/customer_photograph";
import clsx from "clsx";
import { customerGalleryViewCount } from "../../../v2_actions/customer_gallery_view_count";
import { galleryList } from "../../../v2_actions/gallery";
import { goBackFromGalleryPage } from "../../../v2_actions/nav";
import { replaceDashesWithSpaces } from "../../../v2_actions/nav";
import { setMessage } from "../../../actions/trolley";
import { setZoom } from "../../../actions/gallery";
import styles from "./styles";
import { trolleyList } from "../../../v2_actions/trolley";
import { useGalleryFilterSearchParams } from "../../../v2_components/hooks/useGalleryFilterSearchParams";
import { useIsMobile } from "../../../actions/ui";

const useStyles = makeStyles(styles);

const global_styles = css`
  header.MuiAppBar-root {
    height: 60px;
    background: var(--navigation-bg-color);
    @media screen and (max-width: 480px) {
      margin: 0px auto 10px;
      height: 120px;
    }
  }
  .logo_img_map {
    padding-top: 0px;
  }
  .MuiToolbar-root {
    margin: auto;
    min-height: 45px;
    height: 45px;
    width: 100%;
    @media screen and (min-width: 480px) {
      width: 96%;
    }
  }
  span.MuiBadge-root {
    display: block;
    float: right;
  }
  .checkoutButton {
    float: right;
  }
  .checkoutButton button {
    padding: 0px;
  }
  .iconbtn {
    width: 27;
    height: 24;
    padding-right: 0;
    color: black;
  }
  .backbtn {
    margin-right: 18;
  }
  div.MuiToolbar-regular {
    width: 100%;
  }
`;

const TopBar = ({
  gallery,
  width,
  galleryId,
  className,
  showMethod,
  showSideBar,
  setShowMethod,
  setShowSideBar,
  purchaseEntireGaleryEnabled = true,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { gallery_event_name } = useParams();
  const [, , urlParams] = useGalleryFilterSearchParams();

  const message = useSelector((state) => state.trolleyReducer.message);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [
    shouldShowFacialRecognitionDialog,
    setShouldShowFacialRecognitionDialog,
  ] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const galleryListIsLoading = useSelector(() => galleryList.isLoading());
  const indexedGalleryId = useSelector(() =>
    get(basePhotographList.getFilter(), "indexed_gallery_id"),
  );
  const facialRecognitionGalleryIdMatchesGalleryId =
    indexedGalleryId === galleryId;

  useEffect(() => {
    dispatch(trolleyList.ensureTrolleyLoaded());
    if (facialRecognitionGalleryIdMatchesGalleryId) {
      clearFacialRecognitionMatch();
    }
  }, []);

  useEffect(() => {
    if (message) {
      setOpenSnackBar(true);
    }
  }, [message]);

  useEffect(() => {
    if (galleryId) {
      dispatch(galleryList.ensureObjectLoaded(galleryId));
    }
  }, [galleryId]);

  useEffect(() => {
    dispatch(customerGalleryViewCount.incrementGalleryViewCount(galleryId));
  }, [galleryId]);

  const clearFacialRecognitionMatch = () => {
    dispatch(basePhotographList.clearListFilter());
  };

  const renderPhotographerName = () => {
    return (
      <Box className={classes.photoArtPhotographerNameWrapper}>
        <PhotoCameraIcon className={classes.photoArtCameraIcon} />
        <Typography
          component="p"
          className={classes.photographerNameTypography}
          variant="p"
        >
          {get(gallery, "photographer_name")}
        </Typography>
      </Box>
    );
  };

  const renderPhotographerPricing = () => {
    return (
      <Box width="20%" textAlign="center" display="flex">
        <Typography
          variant="p"
          className={classes.photographerPricingTypography}
          onClick={() => setOpenModal(true)}
        >
          Pricing
        </Typography>
      </Box>
    );
  };

  const renderGalleryNameDesktop = () => {
    return (
      <Box>
        <>
          <Typography
            className={
              isMobile
                ? classes.mobiletypography
                : classes.galleryNameTypography
            }
          >
            {gallery_event_name &&
              `${replaceDashesWithSpaces(gallery_event_name)}, `}
            {get(gallery, "gallery_album_name") &&
              `${get(gallery, "gallery_album_name")}, `}
            {get(gallery, "gallery_name")}
          </Typography>
        </>
      </Box>
    );
  };

  const renderGalleryNameMobile = () => {
    return (
      <Box>
        <>
          {gallery_event_name && (
            <Typography
              className={
                isMobile
                  ? classes.mobiletypography
                  : classes.galleryNameTypography
              }
            >
              {gallery_event_name &&
                replaceDashesWithSpaces(gallery_event_name)}
            </Typography>
          )}
          {get(gallery, "gallery_album_name") && (
            <Typography
              className={
                isMobile
                  ? classes.mobiletypography
                  : classes.galleryNameTypography
              }
            >
              {get(gallery, "gallery_album_name")}
            </Typography>
          )}
          <Typography
            className={
              isMobile
                ? classes.mobiletypography
                : classes.galleryNameTypography
            }
          >
            {get(gallery, "gallery_name")}
          </Typography>
        </>
      </Box>
    );
  };

  const renderDestopGalleryPhotographsTopBar = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        marginTop={{ xs: 1, md: 0 }}
        alignItems="center"
        className={clsx(classes.photoArtNavBar)}
        width="100%"
      >
        <Stack direction={"row"} spacing={{ xs: 0 }} display={{ sm: "none" }}>
          <Stack
            spacing={{ xs: 1, sm: 2, md: 12 }}
            direction={{ xs: "row", sm: "column" }}
          >
            {renderPhotographerPricing()}
            {renderPhotographerName()}
          </Stack>
          <Box pt={1}>
            <CheckoutButton variant={"photo_art_gallery_view"} />
          </Box>
        </Stack>
        {!isMobile && (
          <Box
            display="flex"
            sx={{ display: { xs: "none", md: "block" } }}
            alignItems="baseline"
            width={width > 500 ? "97%" : "100%"}
          >
            <Grid container>
              <Grid item xs={0.5}>
                {!showMethod ? (
                  <IconButton
                    className={classes.photoArtBackBtn}
                    onClick={() => {
                      dispatch(setZoom(0));
                      dispatch(setMessage(false));
                      goBackFromGalleryPage(navigate, urlParams);
                    }}
                  >
                    <ArrowBackIcon className={classes.photoArtBackBtn} />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setShowMethod(false);
                    }}
                  >
                    <ArrowBackIcon className={classes.iconbtn} />
                  </IconButton>
                )}
              </Grid>
              <Grid item md={9.5} mt={0.2}>
                {!galleryListIsLoading && renderGalleryNameDesktop()}
              </Grid>
              <Grid item xs={2} className={classes.checkoutBtnBlock}>
                <CheckoutButton
                  isGalleryPhotographShoppingCart
                  variant={"photo_art_gallery_view"}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  const renderMobileGalleryPhotographsTopBar = () => {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          {!showMethod ? (
            <IconButton
              onClick={() => {
                dispatch(setZoom(0));
                dispatch(setMessage(false));
                goBackFromGalleryPage(navigate, urlParams);
              }}
            >
              <ArrowBackIcon className={classes.iconbtn} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setShowMethod(false);
              }}
            >
              <ArrowBackIosIcon className={classes.iconbtn} />
            </IconButton>
          )}
          {renderGalleryNameMobile()}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="right"
          sx={{ width: "90%", position: "fixed", top: 14 }}
        >
          <Box>
            <CheckoutButton variant={"gallery_photographs_view"} />
          </Box>
        </Stack>
        {!isMobile && (
          <Box
            display="flex"
            sx={{ display: { xs: "none", md: "block" } }}
            mt={1}
            alignItems="center"
            width={
              width > 1600
                ? "60%"
                : width > 1400
                  ? "65%"
                  : width > 500
                    ? "90%"
                    : "80%"
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={4} mr={{ xs: 5, md: 0 }}>
                {renderPhotographerName()}
              </Grid>
              <Grid item xs={2}>
                {renderPhotographerPricing()}
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Grid>
              <Grid item xs={4}>
                <CheckoutButton variant={"gallery_photographs_view"} />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <AppBar
      className={clsx(classes.root, classes.appBarRoot, className)}
      elevation={0}
      {...rest}
      color="default"
      height={"200px"}
      position={!isMobile ? "fixed" : "relative"}
    >
      {/* <NotificationContainer /> */}
      <Global styles={global_styles} />
      {!isMobile && !galleryListIsLoading && (
        <Toolbar>{renderDestopGalleryPhotographsTopBar()}</Toolbar>
      )}
      {isMobile && !galleryListIsLoading && (
        <Toolbar>{renderMobileGalleryPhotographsTopBar()}</Toolbar>
      )}
      <PriceModal
        width={width}
        classes={classes}
        openModal={openModal}
        setOpenModal={setOpenModal}
        photoGrapherName={get(gallery, "photographer_name")}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
