import React from "react";
import styles from "./Pill.module.css";
import FaIcon from "../fa-icon/FaIcon";

/**
 * Pill
 * @returns
 */
const Pill = ({
  children,
  onClear,
}: React.PropsWithChildren<{ onClear?: () => void }>) => {
  return (
    <div className={styles.pill}>
      <div className={styles.pilllabel}>{children}</div>
      {onClear && <button className={styles.pillclear} onClick={() => onClear && onClear()}>
        <FaIcon icon="fa-xmark" />
      </button>}
    </div>
  );
};

export default Pill;
