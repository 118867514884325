import {
  FaIcon,
  ListItem,
  ListButton,
  ListItemLabel,
} from "../../design-system/designsystem";

import { Option } from "../../types/orm";

export const EventsFiltersListOptions = ({
  list,
  listType,
  selectedValue,
  onSelected,
}: {
  list: Option[];
  listType: string;
  selectedValue: string | null | undefined;
  onSelected: (option: Option) => void;
}) => {
  const isSelected = (listitem: Option) => {
    if (listType === "Events" || listType === "Photographer") {
      return listitem.label === selectedValue;
    }
    return listitem.value === selectedValue;
  };

  return (
    <>
      {list.map((listitem, i) => {
        return (
          <ListItem align="left" key={`listoption-${i}`}>
            <ListButton fill={true} onClick={() => onSelected(listitem)}>
              <ListItemLabel>{listitem.label}</ListItemLabel>
            </ListButton>
            <ListButton variant="icon" onClick={() => onSelected(listitem)}>
              {isSelected(listitem) && (
                <FaIcon icon="fa-circle-check" color="primary" />
              )}
            </ListButton>
          </ListItem>
        );
      })}
    </>
  );
};
