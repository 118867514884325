import get from "lodash/get";
import cookie from "react-cookies";
import { getStoredLatLng } from "../v2_actions/auth";

const libraries = ["places"];

export const MINIMUM_ZOOM_FOR_SELECTING_LOCATION = 16;
export const DEFAULT_ZOOM = 10;

export const ACTION_FORCE_MAP_STATE = "ACTION_FORCE_MAP_STATE";

export const DEFAULT_LOCATIONS_BY_COUNTRY = {
  za: {
    zoom: 10,
    lat: -31.364637526600113,
    lng: 24.70517530697458,
  },
  au: {
    zoom: 7,
    lat: -33.80376750714667,
    lng: 151.22609875410103,
  },
  us: {
    zoom: 3,
    lat: -25.072625580422045,
    lng: 134.12686793815917,
  },
};

export const forceNewMapState = ({ lat, lng, zoom, bounds }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const map_state = getMapState();
    if (lat) {
      map_state.lat = lat;
    }
    if (lng) {
      map_state.lng = lng;
    }
    if (zoom) {
      map_state.zoom = zoom;
    }
    map_state.is_default = false;
    map_state.bounds = bounds;
    return dispatch({ type: ACTION_FORCE_MAP_STATE, ...map_state });
  };
};

export const clearForcedNewMapState = () => {
  return {
    type: ACTION_FORCE_MAP_STATE,
    lat: null,
    lng: null,
    zoom: null,
    bounds: null,
  };
};

export const getForcedNewMapState = (state) => {
  return get(state, ["mapReducer", "forcedNewMapState"]) || {};
};

export const shouldShowSearchBox = (state) =>
  get(state, ["mapReducer", "showSearchBox"]);

export const setShowSearchBox = (flag) => ({
  type: "MAPINFO/SHOWSEARCHBOX",
  flag,
});

export const setMapState = ({ lat, lng, zoom, bounds }) => {
  const new_state = Object.assign({}, getMapState(), {
    lat,
    lng,
    zoom,
    bounds,
  });
  localStorage.setItem("map", JSON.stringify(new_state));
};

export const getMapState = () => {
  let res = localStorage.getItem("map");
  if (res) {
    try {
      res = JSON.parse(res);
    } catch (e) {
      res = null;
    }
  }

  if (!res || !res.lat || !res.lng || !res.zoom) {
    res = getDefaultLocationForUser();
  } else {
    res.is_default = false;
  }
  return res;
};

export const getDefaultLocationForUser = () => {
  const stored = getStoredLatLng();
  const d = {
    lat: DEFAULT_LOCATIONS_BY_COUNTRY.au.lat,
    lng: DEFAULT_LOCATIONS_BY_COUNTRY.au.lng,
    zoom: DEFAULT_LOCATIONS_BY_COUNTRY.au.zoom,
  };

  if (stored.lat) {
    d.lat = stored.lat;
  }
  if (stored.lng) {
    d.lng = stored.lng;
  }
  if (stored.zoom) {
    d.zoom = stored.zoom;
  }
  d.is_default = true;

  return d;
};

export const getGoogleInitArgs = () => {
  return {
    id: "google-map-script",
    googleMapsApiKey: window.LOCAL_SETTINGS.GOOGLE_MAP_KEY,
    libraries: libraries,
  };
};

export const flyToMyLocation = ({ onDenied }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const permissionStatus = await navigator?.permissions?.query({
      name: "geolocation",
    });
    const hasPermission = permissionStatus?.state;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          dispatch(
            forceNewMapState({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              zoom: DEFAULT_ZOOM,
            }),
          );
        },
        function (positionError) {
          const GPSCoordinatesFromIpAddress =
            navigator.gps_coordinates_from_ip_address;
          if (GPSCoordinatesFromIpAddress) {
            dispatch(
              forceNewMapState({
                lat: GPSCoordinatesFromIpAddress.lat,
                lng: GPSCoordinatesFromIpAddress.lng,
                zoom: DEFAULT_ZOOM,
              }),
            );
          }
        },
      );
    }
  };
};

export const setHasCachedPosition = () => {
  const todayDate = new Date();
  const expirationDate = new Date(todayDate.setDate(todayDate.getDate() + 7));
  cookie.save("is_position_stored", true, {
    path: "/",
    expires: expirationDate,
  });
};

export const hasCachedPosition = () =>
  cookie.load("is_position_stored", { path: "/" });
