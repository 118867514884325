const initialState = {
  activePhotoInfos: [],
  trollyInfoData: {},
  purchasedInfoData: {},
  purchasedPhotoData: false,
  selectAllFlag: false,
  message:false
};

export default function TrolleyReducer(state = initialState, action) {
  switch (action.type) {
    case "TROLLEY/SET_SELECTED_GALLERY_PHOTOGRAPHS":
      return {
        ...state,
        selectedPhotographs: action.selectedPhotographs,
      };
    case "TROLLEY/SELECTALLFLAG":
      return {
        ...state,
        selectAllFlag: action.selectAllFlag,
      };
    case "TROLLEY/SETTROLLEYMESSAGE":
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
}
