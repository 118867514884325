import last from "lodash/last";
import get from "lodash/get";
import React from "react";
import { useSelector } from "react-redux";
import { TrolleyLayout } from "./layout/TrolleyLayout";
import { ShippingAddressForm } from "./ShippingAddressForm";
import { TrolleyPhotographCarousel } from "./TrolleyPhotographCarousel";
import { authenticatedTrolleyList } from "../v2_actions/authenticated_trolley";
import { printPriceConfigurationList } from "../v2_actions/print_price_configuration";

const ShippingAddressPage = () => {
  const authenticated_trolley = useSelector(() =>
    authenticatedTrolleyList.getTrolley(),
  );
  const shipping_page_trolley_photograph = last(
    get(authenticated_trolley, "trolley_photographs"),
  );
  const shipping_page_photograph = get(
    shipping_page_trolley_photograph,
    "photograph",
  );
  const country_print_price_for_shipping_photograph = useSelector(() =>
    printPriceConfigurationList.getObject(
      get(shipping_page_trolley_photograph, "country_print_price"),
    ),
  );
  const shipping_photograph_print_type_id = get(
    country_print_price_for_shipping_photograph,
    ["print_type", "id"],
  );

  const renderTrolleyForm = () => {
    return (
      <ShippingAddressForm authenticated_trolley={authenticated_trolley} />
    );
  };

  const renderCarousel = () => {
    return (
      <TrolleyPhotographCarousel
        photograph={shipping_page_photograph}
        print_type_id={shipping_photograph_print_type_id}
        photo_delivery_type={get(
          shipping_page_trolley_photograph,
          "photo_delivery_type",
        )}
      />
    );
  };

  return (
    <TrolleyLayout
      renderCarousel={renderCarousel}
      renderTrolleyForm={renderTrolleyForm}
    />
  );
};

export default ShippingAddressPage;
