import React from "react";
import styles from "./ListButton.module.css";
import mixinstyles from "../mixins.module.css";

type ListButtonProps = {
  variant?: "default" | "icon";
  fill?: boolean;
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "className"
>;

/**
 * ListButton
 * @returns
 */
const ListButton = ({ fill, variant, children, ...props }: ListButtonProps) => {
  return (
    <button
      {...props}
      className={`${styles.listbutton} ${styles["listbutton" + (variant ?? "default")]} ${fill ? mixinstyles.flexgrow2 : ""}`}
      style={props.style}
    >
      {children}
    </button>
  );
};

export default ListButton;
