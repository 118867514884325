import {
  FaIcon,
  NavButton,
  NavInline,
  NavItem,
  Navigation,
} from "../../design-system/designsystem";

import BossFotosLogo from "../../v2_images/BossFotos-logo-blue on transparent.png";
import React from "react";
import { Strong } from "../../design-system/designsystem";
import { useIsMobile } from "../../actions/ui";

export const NavBar = ({
  onBackClicked,
  onHomeClicked,
}: {
  onBackClicked?: () => void;
  onHomeClicked: () => void;
}) => {
  const isMobile = useIsMobile();

  const handleBackClick = () => {
    onBackClicked && onBackClicked();
  };
  const handleHomeClick = () => {
    onHomeClicked();
  };

  return (
    <Navigation>
      {onBackClicked && (
        <NavButton onClick={handleBackClick}>
          <FaIcon icon="fa-chevron-left" variant="nav" />
        </NavButton>
      )}
      {!isMobile && (
        <NavItem>
          <img
            src={BossFotosLogo}
            alt="BossFotos"
            style={{ marginLeft: "14px", height: "28px" }}
          />
        </NavItem>
      )}
      <NavInline>
        <Strong>Checkout</Strong>
      </NavInline>
      {
        <NavButton onClick={handleHomeClick}>
          <FaIcon icon="fa-house" variant="nav" />
        </NavButton>
      }
    </Navigation>
  );
};
