import React, { ForwardedRef } from "react";
import styles from "./InputText.module.css";

type InputTextProps = {
  hasIconLeft?: boolean;
  hasIconRight?: boolean;
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "className" | "type"
>;

/**
 * Input Text wrapper to mimic input border, allows
 * icons / button to be nested alongside <input />
 * @returns
 */
export const InputTextContainer = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return <div className={styles.inputtextcontainer}>{children}</div>;
};

/**
 * Inner input text box. Wrap with <InputTextContainer/> for border
 * @returns
 */
export const InputTextInner = React.forwardRef(
  (
    { hasIconLeft, hasIconRight, ...props }: InputTextProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <input
        type="text"
        {...props}
        ref={ref}
        className={styles.inputtext}
        style={{
          ...(hasIconLeft && { paddingLeft: "0" }),
          ...(hasIconRight && { paddingRight: "0" }),
        }}
      />
    );
  },
);
