import size from "lodash/size";
import head from "lodash/head";
import get from "lodash/get";
import filter from "lodash/filter";
import { decrementCustomValue, incrementCustomValue } from "./custom_value";

import { CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS } from "./custom_value";
import { ItemList } from "../orm";
import { authenticatedTrolleyList } from "./authenticated_trolley";
import { isLoggedIn } from "./auth";
import { makeLogger } from "../utils/o11y/logger";
import { sentryApi } from "../utils/o11y/sentryApi";
import { trolleyList } from "./trolley";

export const CROSS_CURRENCY_ERROR = "cross_currency";
export const CROSS_GALLERY_TYPE_ERROR = "cross_gallery_type";
export const DIGITAL_ONLY_DELIVERY_TYPE = "digital_only";
export const LOW_RES_DIGITAL_ONLY_DELIVERY_TYPE = "low_res_digital_only";

const logger = makeLogger({
  enabled: true,
  label: "TrolleyPhotographList",
  color: "green",
});

class TrolleyPhotographList extends ItemList {
  getEntityKey() {
    return "customer/trolley_photograph";
  }

  addPhotograph(photograph_id) {
    return this.addPhotographs([photograph_id]);
  }

  addPhotographs(photograph_ids) {
    return async (dispatch, getState) => {
      dispatch(
        incrementCustomValue({
          state: getState(),
          name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
          count: size(photograph_ids),
        }),
      );
      sentryApi.breadcrumb({
        category: "trolley",
        level: "info",
        message: "addPhotographs start",
        data: { photograph_ids },
      });
      dispatch(
        trolleyList.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            sentryApi.breadcrumb({
              category: "trolley",
              level: "info",
              message: "addPhotographs getOrCreateActiveTrolleyId onDone",
              data: { trolley_id },
            });
            dispatch(
              this.saveNewObject({
                trolley: trolley_id,
                photographs_to_add: photograph_ids,
              }),
            )
              .then(() => {
                sentryApi.breadcrumb({
                  category: "trolley",
                  level: "info",
                  message: "addPhotographs saveNewObject onDone",
                  data: { trolley_id, photograph_ids },
                });
                dispatch(trolleyList.invalidateObject(trolley_id));
                dispatch(trolleyList.ensureObjectLoaded(trolley_id));
                if (isLoggedIn()) {
                  dispatch(
                    authenticatedTrolleyList.getOrCreateActiveTrolleyId({
                      onDone: (trolley_id) => {
                        dispatch(
                          authenticatedTrolleyList.invalidateObject(trolley_id),
                        );
                        dispatch(
                          authenticatedTrolleyList.ensureObjectLoaded(
                            trolley_id,
                          ),
                        );
                      },
                    }),
                  );
                }
              })
              .catch((e) => {
                sentryApi.error("trolley_addphotos_error", {
                  trolley_id,
                  photograph_ids,
                  error: e?.message ?? e.toString(),
                });
              });
          },
        }),
      );
    };
  }

  getIsAddingImage(photograph_id) {
    return this.getIsSavingObject(photograph_id);
  }

  removePhotograph(photograph_id) {
    logger.log("removePhotograph", photograph_id);
    return async (dispatch, getState) => {
      dispatch(
        decrementCustomValue({
          state: getState(),
          name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
          count: 1,
        }),
      );

      dispatch(
        trolleyList.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            const trolley = trolleyList.getTrolley();

            logger.log("removePhotograph getOrCreateActiveTrolleyId => ", {
              trolley_id,
              photograph_id,
              trolley,
            });

            const trolley_photograph = head(
              filter(
                trolley.trolley_photographs,
                (trolley_photograph) =>
                  trolley_photograph.photograph.id === photograph_id,
              ),
            );

            dispatch(this.deleteObject(trolley_photograph.id))
              .then(() => {
                dispatch(trolleyList.invalidateObject(trolley_id));
                dispatch(trolleyList.ensureObjectLoaded(trolley_id));

                if (isLoggedIn()) {
                  dispatch(
                    authenticatedTrolleyList.invalidateObject(trolley_id),
                  );
                  dispatch(
                    authenticatedTrolleyList.ensureObjectLoaded(trolley_id),
                  );
                }
              })
              .catch((e) => {
                sentryApi.error("trolley_deletephotos_error", {
                  trolley_id,
                  photoid: trolley_photograph.id,
                  error: e?.message ?? e.toString(),
                });
              });
          },
        }),
      );
    };
  }

  canAddPhotographToTrolley(gallery, trolley) {
    let result;
    const trolleyCurrencyCode = get(trolley, "trolley_currency_code");
    const galleryCurrencyCode = get(gallery, "gallery_currency_code");

    if (trolleyCurrencyCode && galleryCurrencyCode !== trolleyCurrencyCode) {
      result = { can_add: false, reason: CROSS_CURRENCY_ERROR };
    } else {
      result = { can_add: true };
    }
    return result;
  }

  isPhotographInTrolley(photograph_id, trolley) {
    if (trolley) {
      const is_trolley_photograph = Boolean(
        head(
          filter(
            get(trolley, "trolley_photographs"),
            (trolley_photograph) =>
              get(trolley_photograph, ["photograph", "id"]) === photograph_id,
          ),
        ),
      );
      return is_trolley_photograph;
    }
    return false;
  }

  isDigitalDeliveryType(photo_delivery_type) {
    switch (photo_delivery_type) {
      case "digital_only":
        return true;
      case "low_res_digital_only":
        return true;
      case null:
        return true;
      default:
        return false;
    }
  }
}

export const trolleyPhotographList = new TrolleyPhotographList(
  "trolley_photograph_default",
);
