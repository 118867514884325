import React from "react";
import styles from "./Viewport.module.css";

/**
 * Wrapper for the entire screen
 * @returns
 */
const ViewPort = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className={styles.viewport}>{children}</div>;
};

export default ViewPort;
