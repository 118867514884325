import size from "lodash/size";
import get from "lodash/get";
import { signIn, signUp, edifProfileApi } from "../apis/userapi";
import { announceUserLoggedIn } from "../v2_actions/auth";
import { trolleyList } from "../v2_actions/trolley";
import { authenticatedTrolleyList } from "../v2_actions/authenticated_trolley";
import {
  setActiveTrolleyCookieId,
  getActiveTrolleyCookieId,
} from "../v2_actions/trolley_cookie";
import { sentryApi } from "../utils/o11y/sentryApi";
import cookie from "react-cookies";

export const userInfo = (userInfo) => ({
  type: "USER/USERINFO",
  userInfo,
});

export const setForgotEmail = (forgotEmail) => ({
  type: "USER/SETFORGOTEMAIL",
  forgotEmail,
});

export const setErrorMessage = (errorMessage) => ({
  type: "USER/ERRORMESSAGE",
  errorMessage,
});

export const setShowReteralModal = (showReteralModal) => ({
  type: "USER/SHOWRETERALMODAL",
  showReteralModal,
});

export const signInAction = (
  data,
  trolley,
  generalInfo,
  country,
  navigate,
  trolley_id_from_url,
) => {
  sentryApi.breadcrumb({
    category: "login",
    level: "info",
    message: "start",
    data: {
      email: data.email,
      cookies: Object.keys(cookie?.loadAll()),
      trolley_id_from_url,
    },
  });

  return (dispatch) => {
    signIn(data)
      .then((res) => {
        const token = res.data.token;
        const userInfoData = res.data.user;
        const trolley = trolleyList.getTrolley();
        dispatch(announceUserLoggedIn(token, userInfoData));
        dispatch(authenticatedTrolleyList.trySetTrolleyCustomer());

        if (
          size(get(trolley, "trolley_photographs")) > 0 ||
          trolley_id_from_url
        ) {
          navigate(
            `/customer/shopping/${trolley_id_from_url || get(trolley, "id")}`,
          );
        } else {
          navigate(`/customer/events`);
        }

        dispatch(setShowReteralModal(false));
        dispatch(userInfo(userInfoData));

        sentryApi.breadcrumb({
          category: "login",
          level: "info",
          message: "success",
          data: {},
        });
      })
      .catch((error) => {
        // TODO:
        // error.response.data will now either be user_not_found or invalid_credentials
        // make use of error.response.data for setErrorMessage
        // please ignore sentry when error.response.status == 401
        // 401s are expected and should not be logged
        if (error) {
          sentryApi.error("login_error", {
            error: error?.message ?? error?.toString(),
          });
          dispatch(
            setErrorMessage({
              showMessage: "You have entered an invalid email or password",
              showMethod: true,
            }),
          );
        }
      });
  };
};

export const signUpAction = (
  data,
  activeTrolleyPhotos,
  generalInfo,
  country,
  navigate,
) => {
  return (dispatch) => {
    signUp(data)
      .then((res) => {
        if (res.data === "User Resiger Failed!") {
          return false;
        } else {
          const userInfoData = res.data.user;
          const token = res.data.token;
          if (userInfoData.is_staff === false) {
            dispatch(announceUserLoggedIn(token, userInfoData));
            const trolley = trolleyList.getTrolley();
            dispatch(authenticatedTrolleyList.trySetTrolleyCustomer());
            if (size(get(trolley, "trolley_photographs")) > 0) {
              navigate(`/customer/v2_trolley`);
            } else {
              navigate(`/customer/events`);
            }
          }
          dispatch(setShowReteralModal(true));
          dispatch(userInfo(userInfoData));
        }
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };
};

export const eidfProfileAction = (data) => {
  return (dispatch) => {
    edifProfileApi(data).then((res) => {
      if (res.data.change_pass_flag === false) {
        dispatch(
          setErrorMessage({
            showMessage: "Incorrect old password!",
            showMethod: true,
          }),
        );
      } else {
        dispatch(
          setErrorMessage({
            showMessage: "Profile Update Successfully!",
            showMethod: false,
          }),
        );
        let userInfoData = res.data.results;
        const token = res.data.token;
        userInfoData.profile_photo =
          `${window.LOCAL_SETTINGS.API_BASE}/media/` +
          res.data.results.profile_photo;
        dispatch(userInfo(userInfoData));
        dispatch(announceUserLoggedIn(token, userInfoData));
      }
    });
  };
};

export const clearAllStorage = () => {
  window.localStorage.clear();

  const trolley_id = getActiveTrolleyCookieId();

  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }

  if (trolley_id) {
    setActiveTrolleyCookieId(trolley_id);
  }
};
