import React from "react";
import styles from "./ListItem.module.css";
import mixinstyles from "../mixins.module.css";

type Alignment = "left" | "center" | "right";

/**
 * ListItem
 * @returns
 */
const ListItem = ({
  align,
  children,
}: React.PropsWithChildren<{ align?: Alignment }>) => {
  return (
    <div
      role="listitem"
      className={`${styles.listitem} ${mixinstyles["flexalignment" + (align ?? "left")]}`}
    >
      {children}
    </div>
  );
};

export default ListItem;
