import { ChangeEventHandler, useEffect, useState } from "react";
import {
  Header,
  InputTextContainer,
  InputTextInner,
  NavButton,
} from "../../design-system/designsystem";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useGalleryFilterSearchParams } from "../../v2_components/hooks/useGalleryFilterSearchParams";
import { useNavigate } from "react-router-dom";

const DEFAULT_TIMEOUT_TO_CHANGE_ANY_FIELD_FILTER_CHANGE = 700;

/**
 * Searches backend via generic "any" filter to query all data
 * @returns
 */
export const SearchBarAny = () => {
  const navigate = useNavigate();
  const [galleryFilterSearchParams, syncGalleryFilterSearchParams] =
    useGalleryFilterSearchParams();

  const [anyFieldInputText, setAnyFieldInputText] = useState(
    galleryFilterSearchParams.any,
  );

  useEffect(
    function syncTextboxWithUrl() {
      if (!galleryFilterSearchParams.any) {
        setAnyFieldInputText("");
      }
    },
    [galleryFilterSearchParams.any],
  );

  const isSearchActive = galleryFilterSearchParams.any !== undefined;

  const [timeoutId, setTimeoutId] = useState<number>();
  const handleAnyFieldInputTextChanged: ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value } = e.target;
    setAnyFieldInputText(value ?? "");
    if (timeoutId) {
      clearTimeout(timeoutId); // cancel pending redirect
    }
    const id = window.setTimeout(() => {
      // Note we're not using useGalleryFilterSearchParams here. We're
      // forcing all search back to the events page to clear filter and any
      // UI context of galleries/albums. The 'any' url param will still
      // be accessible / valid through useGalleryFilterSearchParams
      if (!anyFieldInputText) {
        navigate(`/customer/events/`);
      } else {
        syncGalleryFilterSearchParams({ any: value });
      }
    }, DEFAULT_TIMEOUT_TO_CHANGE_ANY_FIELD_FILTER_CHANGE);
    setTimeoutId(id);
  };
  const handleSearchClick = () => {
    syncGalleryFilterSearchParams({ any: anyFieldInputText });
  };

  const handleClearSearch = () => {
    setAnyFieldInputText("");
    syncGalleryFilterSearchParams({ any: undefined });
  };

  return (
    <Header>
      <InputTextContainer>
        <InputTextInner
          value={anyFieldInputText}
          onChange={handleAnyFieldInputTextChanged}
          hasIconRight={true}
          placeholder="Search Galleries by Name, Event, School, Photographer"
        />
        {isSearchActive ? (
          <NavButton onClick={handleClearSearch} variant="input">
            <ClearIcon />
          </NavButton>
        ) : (
          <NavButton onClick={handleSearchClick} variant="input">
            <SearchIcon />
          </NavButton>
        )}
      </InputTextContainer>
    </Header>
  );
};
