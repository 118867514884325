import { NavInline, Navigation, Shell, ViewPort } from "../designsystem";

import BossFotosLogo from "../../v2_images/BossFotos-logo-blue on transparent.png";
import LoadingSpinner from "../../views/components/LoadingSpinner";
import { isWindowInIframe } from "../../v2_actions/nav";

function LoadingHorseShoe() {
  const isInIframe = isWindowInIframe();

  return (
    <ViewPort>
      <Shell>
        {!isInIframe && (
          <Navigation>
            <NavInline>
              <img
                src={BossFotosLogo}
                alt="BossFotos"
                style={{ height: "28px" }}
              />
            </NavInline>
          </Navigation>
        )}
        <LoadingSpinner />
      </Shell>
    </ViewPort>
  );
}

export default LoadingHorseShoe;
