import React from "react";

import {
  FaIcon,
  Navigation,
  NavInline,
  NavButton,
} from "../../design-system/designsystem";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import BossFotosLogo from "../../v2_images/BossFotos-logo-blue on transparent.png";

export const NavBar = ({
  onMenuClicked,
  onHelpClicked,
}: {
  onMenuClicked: () => void;
  onHelpClicked?: () => void;
}) => {
  const handleMenuIconClick = () => {
    onMenuClicked();
  };
  const handleHelpIconClick = () => {
    onHelpClicked && onHelpClicked();
  };

  return (
    <Navigation>
      <NavButton onClick={handleMenuIconClick}>
        <FaIcon icon="fa-bars" variant="nav" />
      </NavButton>
      <NavInline>
        <img src={BossFotosLogo} alt="BossFotos" style={{ height: "28px" }} />
      </NavInline>
      {
        <NavButton onClick={handleHelpIconClick}>
          <HelpOutlineIcon />
        </NavButton>
      }
    </Navigation>
  );
};
