import "./index.css";
import "./tokens-bossfotos.css";

import App from "./App";
import { DevTools } from "./devtools/DevTools";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import configureStore from "./config/configureStore";
import reportWebVitals from "./reportWebVitals";
import { syncORMWithStore } from "./orm";
import { initialiseSentry } from "./utils/o11y/sentrySetup";

const isDev = process.env.NODE_ENV === "development";

initialiseSentry(window.LOCAL_SETTINGS?.SENTRY_DSN, isDev);

const root = ReactDOM.createRoot(document.getElementById("root"));
export const store = configureStore();
syncORMWithStore(store);

const StrictModeProdOnly = ({ children }) => {
  if (isDev) {
    return <>{children}</>;
  }
  // StrictMode runs useEffect() TWICE in dev builds
  // see https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  return <React.StrictMode>{children}</React.StrictMode>;
};

root.render(
  <StrictModeProdOnly>
    <Provider store={store}>
      <App />
      {false && isDev && <DevTools />}
    </Provider>
  </StrictModeProdOnly>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
