import map from "lodash/map";
import head from "lodash/head";
import get from "lodash/get";
import first from "lodash/first";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
  getCustomValue,
  setCustomValue,
} from "../../v2_actions/custom_value";
import {
  Convert12hrTimeTo24hrTime,
  DEFAULT_TIME_END_12HR,
  DEFAULT_TIME_END_24HR,
  DEFAULT_TIME_START_12HR,
  DEFAULT_TIME_START_24HR,
} from "../../v2_actions/time";
import { Global, css } from "@emotion/react";
import { addHours, parseISO } from "date-fns";
import {
  basePhotographList,
  photographList,
} from "../../v2_actions/customer_photograph";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BibNumber from "../../v2_components/BibNumber";
import CloseIcon from "@mui/icons-material/Close";
import DigitalPricesDialog from "./DigitalPricesDialog";
import FaceIcon from "@mui/icons-material/Face";
import FacialRecognition from "../../v2_components/FacialRecognition";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import GalleryPhotographs from "../../v2_components/GalleryPhotographs";
import LaptopIcon from "@mui/icons-material/Laptop";
import Looks6Icon from "@mui/icons-material/Looks6";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import RangeSlider from "../../v2_components/form/TimeRangeSlider";
import ReusableTabs from "../../v2_components/TabPanel";
import SideBar from "../../layout/sidebar";
import ThirtyFpsSelectIcon from "@mui/icons-material/ThirtyFpsSelect";
import TopBar from "./topbar";
import { galleryList } from "../../v2_actions/gallery";
import { makeLogger } from "../../utils/o11y/logger";
import { makeStyles } from "@material-ui/core";
import style from "./styles";
import { trolleyList } from "../../v2_actions/trolley";
import { useIsMobile } from "../../actions/ui";

const logger = makeLogger({
  enabled: true,
  label: "GalleryPhotographs_Page",
  color: "blue",
});

const useStyles = makeStyles(style);

const global_styles = css`
  div.MuiAccordion-root {
    font-size: 12px;
    background: #f4f4f4;
  }
  div.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: inherit;
  }
  div.MuiAccordion-root {
    box-shadow: none;
  }
  div.MuiAccordionSummary-root {
    padding-left: 0px;
    background: #f4f4f4;
  }
  div.MuiAccordionSummary-content {
    left: 35px;
    position: absolute;
  }
  div.MuiAccordionSummary-expandIconWrapper {
    left: 0px;
    position: absolute;
  }
  div.MuiAccordionSummary-expandIconWrapper svg {
    width: 25px;
    height: 24px;
  }
`;

const GalleryPhotographs_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { galleryId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [width] = useState(window.innerWidth);
  const [bibNumber, setBibNumber] = useState();
  const [showMethod, setShowMethod] = useState(false);
  const [isFacialRecognitionFilterActive, setIsFacialRecognitionFilterActive] =
    useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [submittedBibNumber, setSubmittedBibNumber] = useState();
  const [accordianExpanded, setAccordianExpanded] = useState(false);
  const photographs = photographList.getVisibleObjects();
  const galleries = useSelector(() => galleryList.getVisibleObjects());
  const gallery = head(galleries);
  const openDigitalPricesDialog = useSelector(
    (state) =>
      getCustomValue({
        state,
        name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
      }) || false,
  );
  const [showClearFiltersButton, setShowClearFiltersButton] = useState(false);
  const [clearFiltersWasClicked, setClearFiltersWasClicked] = useState(false);
  const [bibNumberFilterHasInput, setBibNumberFilterHasInput] = useState(false);
  const [expandActiveMobileTabFilter, setExpandActiveMobileTabFilter] =
    useState(true);
  const [
    mayPerformBibNumberIndexingOfGalleryPhotographs,
    setMayPerformBibNumberIndexingOfGalleryPhotographs,
  ] = useState(false);
  const [
    mayPerformFacialRecognitionIndexingOfGalleryPhotographs,
    setMayPerformFacialRecognitionIndexingOfGalleryPhotographs,
  ] = useState(false);
  const [timeOfActivity, setTimeOfActivity] = useState({
    start: DEFAULT_TIME_START_12HR,
    end: DEFAULT_TIME_END_12HR,
  });
  const [timeOfActivity24HourFormat, setTimeOfActivity24HourFormat] = useState({
    start: DEFAULT_TIME_START_24HR,
    end: DEFAULT_TIME_END_24HR,
  });
  const customerFaceToken = useSelector(() =>
    get(photographList.getFilter(), "customer_face_token"),
  );
  const scrollPane = useRef(null);
  const minTimeOfActivity = get(first(photographs), "photograph_taken_at");

  // change to preset max time to min+1 hour because infinite scroll
  // prevents last photo time being accessed. This needs a backend solution
  // to provide the actual end time
  const maxTimeOfActivity =
    minTimeOfActivity === undefined
      ? undefined
      : addHours(parseISO(minTimeOfActivity), 1).toISOString();

  const [defaultGalleryTimeRange, setDefaultGalleryTimeRange] = useState({
    galleryId: undefined,
    minTimeOfActivity,
    maxTimeOfActivity,
  });

  logger.log(
    "time range",
    { minTimeOfActivity, maxTimeOfActivity },
    defaultGalleryTimeRange,
  );

  useEffect(() => {
    dispatch(trolleyList.invalidateList());
    dispatch(trolleyList.ensureTrolleyLoaded());
    return () => {
      clearFilters();
      setMayPerformFacialRecognitionIndexingOfGalleryPhotographs(false);
    };
  }, []);

  useEffect(() => {
    if (galleryId) {
      dispatch(
        galleryList.updateListFilter({
          db_id: galleryId,
        }),
      );
      dispatch(
        galleryList.fetchListIfNeeded({
          callSource: "GalleryPhotographs_Page",
        }),
      );
    }
  }, [galleryId]);

  useEffect(() => {
    const bibNumberIsPristine = !submittedBibNumber;
    const timeOfActivityIsPristine =
      timeOfActivity24HourFormat.start === DEFAULT_TIME_START_24HR &&
      timeOfActivity24HourFormat.end === DEFAULT_TIME_END_24HR;
    const facialRecognitionIsPristine = !customerFaceToken;
    if (
      bibNumberIsPristine &&
      timeOfActivityIsPristine &&
      facialRecognitionIsPristine &&
      !isFacialRecognitionFilterActive
    ) {
      setShowClearFiltersButton(false);
    } else {
      setShowClearFiltersButton(true);
    }
  }, [
    timeOfActivity24HourFormat,
    submittedBibNumber,
    isFacialRecognitionFilterActive,
    customerFaceToken,
  ]);

  useEffect(() => {
    if (isFacialRecognitionFilterActive) {
      setTimeOfActivity({
        start: DEFAULT_TIME_START_12HR,
        end: DEFAULT_TIME_END_12HR,
      });
    }
  }, [isFacialRecognitionFilterActive, customerFaceToken]);

  useLayoutEffect(() => {
    // focus scroll pane on load
    scrollPane.current?.focus();
  }, []);

  const timeChangeHandler = (time) => setTimeOfActivity(time);

  const onChangeBibNumber = (event) => setBibNumber(event.target.value);

  const onSubmitBibNumber = () => {
    setBibNumberFilterHasInput(true);
    setSubmittedBibNumber(bibNumber);
    setExpandActiveMobileTabFilter(false);
  };

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const triggerFacialRecognitionPhotographIndexing = () => {
    setMayPerformFacialRecognitionIndexingOfGalleryPhotographs(true);
  };

  const triggerBibGalleryPhotographIndexing = () => {
    setMayPerformBibNumberIndexingOfGalleryPhotographs(true);
  };

  const changeCompleteHandler = (time) => {
    !isMobile && setTimeOfActivity24HourFormat(Convert12hrTimeTo24hrTime(time));
  };

  const handleAccordianTabChange = (panel) => (event, isExpanded) => {
    setAccordianExpanded(isExpanded ? panel : false);
  };

  const onClickSetTime = () => {
    setTimeOfActivity24HourFormat(Convert12hrTimeTo24hrTime(timeOfActivity));
    setExpandActiveMobileTabFilter(false);
  };

  const onCancelTimeOfActivityFilter = () => {
    setTimeOfActivity({
      start: DEFAULT_TIME_START_12HR,
      end: DEFAULT_TIME_END_12HR,
    });
    setTimeOfActivity24HourFormat({
      start: DEFAULT_TIME_START_24HR,
      end: DEFAULT_TIME_END_24HR,
    });
    setExpandActiveMobileTabFilter(false);
  };

  const clearFilters = () => {
    setBibNumber("");
    setIsFacialRecognitionFilterActive(false);
    setSubmittedBibNumber("");
    setClearFiltersWasClicked(true);
    setTimeOfActivity({
      start: DEFAULT_TIME_START_12HR,
      end: DEFAULT_TIME_END_12HR,
    });
    setTimeOfActivity24HourFormat({
      start: DEFAULT_TIME_START_24HR,
      end: DEFAULT_TIME_END_24HR,
    });

    setExpandActiveMobileTabFilter(false);
    dispatch(photographList.clearListFilter());
    dispatch(basePhotographList.clearListFilter());
  };

  const renderClearFilters = () => {
    return (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          onClick={clearFilters}
          endIcon={<CloseIcon />}
          className={classes.clearFiltersButton}
        >
          <p className={classes.clearFiltersButtonText}>Clear Filters</p>
        </Button>
      </Stack>
    );
  };

  const renderFacialRecognitionFilter = () => {
    return (
      <Stack direction={{ xs: "column", sm: "row" }}>
        <FacialRecognition
          gallery={gallery}
          setIsFacialRecognitionFilterActive={
            setIsFacialRecognitionFilterActive
          }
          clearFiltersWasClicked={clearFiltersWasClicked}
          setClearFiltersWasClicked={setClearFiltersWasClicked}
          expandActiveMobileTabFilter={expandActiveMobileTabFilter}
          setExpandActiveMobileTabFilter={setExpandActiveMobileTabFilter}
          mayPerformFacialRecognitionIndexingOfGalleryPhotographs={
            mayPerformFacialRecognitionIndexingOfGalleryPhotographs
          }
          setMayPerformFacialRecognitionIndexingOfGalleryPhotographs={
            setMayPerformFacialRecognitionIndexingOfGalleryPhotographs
          }
        />
      </Stack>
    );
  };

  const renderBibNumberFilter = () => {
    return (
      <BibNumber
        gallery={gallery}
        bibNumber={bibNumber}
        onSubmitBibNumber={onSubmitBibNumber}
        onChangeBibNumber={onChangeBibNumber}
        mayPerformBibNumberIndexingOfGalleryPhotographs={
          mayPerformBibNumberIndexingOfGalleryPhotographs
        }
        setMayPerformBibNumberIndexingOfGalleryPhotographs={
          setMayPerformBibNumberIndexingOfGalleryPhotographs
        }
      />
    );
  };

  const renderTimeOfActivityFilter = () => {
    logger.log("renderTimeOfActivityFilter", {
      timeOfActivity,
      minTimeOfActivity,
      maxTimeOfActivity,
    });
    return (
      <Stack direction={{ xs: "column", sm: "row" }}>
        <RangeSlider
          value={timeOfActivity}
          minValue={minTimeOfActivity}
          maxValue={maxTimeOfActivity}
          onClickSetTime={onClickSetTime}
          handleChange={timeChangeHandler}
          isFacialRecognitionFilterActive={isFacialRecognitionFilterActive}
          changeCompleteHandler={changeCompleteHandler}
          onClickCancelRangeSlider={onCancelTimeOfActivityFilter}
        />
      </Stack>
    );
  };

  const renderDigitalPrices = () => {
    return <Typography>do renderDigitalPrices</Typography>;
  };

  const renderPrintingAndFramingPrices = () => {
    return <Typography>do renderPrintingAndFramingPrices</Typography>;
  };

  const sideMenuFilters = [
    {
      icon: <PersonSearchIcon />,
      for_facial_recognition: true,
      heading: "Facial Recognition",
      body: renderFacialRecognitionFilter(),
    },
    /* {
     *   icon: <Looks6Icon />,
     *   for_bib_recognition: true,
     *   heading: "Bib number",
     *   body: renderBibNumberFilter(),
     * }, */
    {
      icon: <AccessTimeIcon />,
      heading: "Time of Activity",
      body: renderTimeOfActivityFilter(),
    },
  ];

  const sideMenuPricing = [
    {
      icon: <LaptopIcon />,
      heading: "Digital Prices",
      body: renderDigitalPrices(),
    },
    {
      icon: <FilterFramesIcon />,
      heading: "Printing & Framing",
      body: renderPrintingAndFramingPrices(),
    },
  ];

  const mobileTabPanelColumns = [
    {
      icon: <AccessTimeIcon />,
      title: "Activity Time",
    },
    {
      icon: <FaceIcon />,
      title: "Facial Recognition",
    },
    {
      icon: <ThirtyFpsSelectIcon />,
      title: "Bib Number",
    },
  ];

  const renderMobileGalleryPhotographsParentView = () => {
    return (
      <Box>
        <ReusableTabs
          scrollButtons
          variant="scrollable"
          columns={mobileTabPanelColumns}
          expandActiveMobileTabFilter={expandActiveMobileTabFilter}
          setExpandActiveMobileTabFilter={setExpandActiveMobileTabFilter}
          rows={[
            renderTimeOfActivityFilter(),
            renderFacialRecognitionFilter(),
            renderBibNumberFilter(),
          ]}
        />
      </Box>
    );
  };

  const renderDesktopGalleryPhotographsParentView = () => {
    return (
      <aside className={classes.sidebarForGalleryPhotographDesktop}>
        <div className={classes.sidebarForGalleryPhotographDesktopSpacer}></div>
        <p>
          {showClearFiltersButton && renderClearFilters()}
          {!showClearFiltersButton && (
            <p className={classes.sidebarForGalleryPhotographDesktopHeading}>
              Filters
            </p>
          )}
        </p>
        {map(sideMenuFilters, (sideMenuFilter, index) => (
          <Accordion
            className={classes.accordion}
            IconButtonProps={{ edge: "start" }}
          >
            <AccordionSummary
              id={index}
              onClick={
                (sideMenuFilter.for_facial_recognition &&
                  triggerFacialRecognitionPhotographIndexing) ||
                (sideMenuFilter.for_bib_recognition &&
                  triggerBibGalleryPhotographIndexing)
              }
              expandIcon={sideMenuFilter.icon}
              aria-controls="panel1a-content"
              className={classes.galleryPhotographsAccordionSummary}
            >
              <p className={classes.accordianHeading}>
                {sideMenuFilter.heading}
              </p>
            </AccordionSummary>
            <AccordionDetails>{sideMenuFilter.body}</AccordionDetails>
          </Accordion>
        ))}
        <p className={classes.sidebarForGalleryPhotographDesktopHeading}>
          Pricing
        </p>
        <Accordion
          className={classes.accordion}
          IconButtonProps={{ edge: "start" }}
        >
          <AccordionSummary
            onClick={() =>
              dispatch(
                setCustomValue({
                  name: CUSTOM_VALUE_OPEN_DIGITAL_PRICES_DIALOG,
                  value: true,
                }),
              )
            }
            expandIcon={<LaptopIcon />}
            aria-controls="panel1a-content"
            className={classes.galleryPhotographsAccordionSummary}
          >
            <p className={classes.accordianHeading}>Digital Prices</p>
          </AccordionSummary>
        </Accordion>
      </aside>
    );
  };

  return (
    <>
      <Global styles={global_styles} />
      {!isMobile && (
        <TopBar
          gallery={gallery}
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
          galleryId={galleryId}
          showMethod={showMethod}
          setShowMethod={setShowMethod}
          width={width}
        />
      )}
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!isMobile && renderDesktopGalleryPhotographsParentView()}
      <div
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        className={
          !showSideBar
            ? `${classes.nonMennuWrapper} ${classes.nonMennuWrapperForGalleryPhotographs}`
            : classes.menuWrapper
        }
      >
        <div
          className={classes.contentContainer + " gallery-content-container"}
        >
          <div className={classes.content} tabindex="0" ref={scrollPane}>
            {isMobile && (
              <>
                <TopBar
                  gallery={gallery}
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  galleryId={galleryId}
                  showMethod={showMethod}
                  setShowMethod={setShowMethod}
                  width={width}
                />
                {renderMobileGalleryPhotographsParentView()}
              </>
            )}
            <GalleryPhotographs
              gallery_id={galleryId}
              bibNumber={submittedBibNumber}
              timeOfActivity={timeOfActivity24HourFormat}
              onClearFilters={clearFilters}
            />
            {openDigitalPricesDialog && (
              <DigitalPricesDialog
                gallery={gallery}
                openDigitalPricesDialog={openDigitalPricesDialog}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryPhotographs_Page;
