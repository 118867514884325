import get from "lodash/get";
import { Modal, makeStyles } from "@material-ui/core";

import React, { useEffect, useRef } from "react";
import { sentryApi } from "./o11y/sentryApi";
import { setMessage } from "../actions/trolley";
import { trolleyProcessPayment } from "../v2_actions/trolley_process_payment";
import { useDispatch } from "react-redux";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    top: "10%",
    left: "50%",
    padding: 10,
    transform: `translate(-50%, -10%)`,
    backgroundColor: "#fff",
    border: "1px solid black",
  },
});
const useStyles = makeStyles(styles);

export const SimplifyPaymentGateway = ({
  trolley,
  onClose,
  showSpinner,
  onPaymentSuccessfulAndConfirmed,
  onPaymentFailure,
}) => {
  const classes = useStyles();
  const iframeRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const tag = document.createElement("script");
    tag.type = "text/javascript";
    tag.setAttribute(
      "src",
      "https://www.simplify.com/commerce/simplify.pay.js",
    );
    const element = document.getElementsByTagName("head");
    element[0].appendChild(tag);
  }, []);

  const onPaymentApproved = (simplifyResponse) => {
    const payment_ref = simplifyResponse.id;
    dispatch(
      trolleyProcessPayment.pay({
        trolley_id: trolley.id,
        gateway_payment_ref: payment_ref,
      }),
    ).then((res) => {
      if (res.errors) {
        onPaymentFailure(
          `Failed to verify your payment. Please call the helpdesk, your payment reference is ${payment_ref}`,
        );
      } else {
        onPaymentSuccessfulAndConfirmed();
      }
    });
    showSpinner(true);
  };

  const initSimplify = () => {
    if (window.SimplifyCommerce) {
      window.SimplifyCommerce.hostedPayments(function (response) {
        const paymentStatus = response?.data?.paymentStatus;

        if (!paymentStatus) {
          onPaymentFailure(
            `An unknown error occured. Please contact customer support. Please call the helpdesk`,
          );
          if (!response) {
            // unknown
            sentryApi.error("simplify_payment_gateway_error", {
              message: "unknown response",
            });
          } else if (!paymentStatus) {
            // there should be an error response:
            // https://commbank.simplify.com/commerce/docs/tools/hosted-payments
            sentryApi.error("simplify_payment_gateway_error", {
              ...paymentStatus,
            });
          }
          return;
        }

        if (paymentStatus === "APPROVED") {
          onPaymentApproved(response.data);
        } else {
          onClose();
          dispatch(setMessage(paymentStatus));
        }
      });
    }
  };

  return (
    <>
      {get(trolley, "total_price_owing_by_customer") > 2 && (
        <Modal open={true} onClose={onClose}>
          <div className={classes.container}>
            <iframe
              ref={iframeRef}
              title="checkout"
              name="my-hosted-form"
              data-sc-key={window.LOCAL_SETTINGS.SIMPLIFY_COMMERCE_PUBLIC_KEY}
              data-name="checkout trolley"
              data-operation="create.payment"
              data-description="checkout trolley descrption"
              data-reference="99999"
              data-amount={trolley.total_price_owing_by_customer * 100}
              data-color="#12B830"
              onLoad={initSimplify}
            ></iframe>
          </div>
        </Modal>
      )}
    </>
  );
};
