import get from "lodash/get";
import {
  clearActiveTrolleyCookieId,
  getActiveTrolleyCookieId,
  setActiveTrolleyCookieId,
} from "../v2_actions/trolley_cookie";

import { getCustomValue, setCustomValue } from "./custom_value";
import { CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS } from "./custom_value";
import { ItemList } from "../orm";
import { makeLogger } from "../utils/o11y/logger";

const logger = makeLogger({
  enabled: true,
  label: "TrolleyList",
  color: "green",
});

export class TrolleyList extends ItemList {
  getEntityKey() {
    return "customer/trolley";
  }

  getOrCreateActiveTrolleyId({ gallery, onDone }) {
    return async (dispatch, getState) => {
      const trolley_id = this._getActiveTrolleyId();
      if (!trolley_id) {
        dispatch(this.createTrolley({ gallery })).then((res) => {
          this.setActiveTrolleyId(res.id);
          if (onDone) {
            onDone(res.id);
          }
        });
      } else {
        onDone(trolley_id);
      }
    };
  }

  getTrolley() {
    const trolley_id = this._getActiveTrolleyId();
    if (!trolley_id) {
      return null;
    }
    const trolley = this.getObject(trolley_id);

    return trolley;
  }

  refetchTrolley() {
    return async (dispatch, getState) => {
      dispatch(
        this.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            // // I think this might be causing an infinite loop
            // dispatch(this.invalidateObject(trolley_id))
            // ///

            dispatch(this.ensureObjectLoaded(trolley_id));
          },
        }),
      );
    };
  }

  ensureTrolleyLoaded() {
    return async (dispatch, getState) => {
      dispatch(
        this.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            dispatch(this.ensureObjectLoaded(trolley_id)).then((json) => {
              const trolley = json[0];
              if (
                trolley &&
                getCustomValue({
                  state: this.getState(),
                  name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
                }) === undefined
              ) {
                logger.log(
                  this.getEntityKey(),
                  "custom value: initialising",
                  trolley.number_of_photos,
                );
                dispatch(
                  setCustomValue({
                    name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
                    value: trolley.number_of_photos,
                  }),
                );
              }
            });
          },
        }),
      );
    };
  }

  resetTrolley(onDone) {
    this._clearActiveTrolleyId();
    const trolley = this.getTrolley();

    return async (dispatch, getState) => {
      dispatch(
        setCustomValue({
          name: CUSTOM_VALUE_NUM_TROLLEY_PHOTOGRAPHS,
          value: 0,
        }),
      );
      if (trolley) {
        dispatch(this.deleteObject(trolley.id)).then(onDone);
      } else {
        if (onDone) {
          onDone();
        }
      }
    };
  }

  trySetTrolleyCustomer() {
    return async (dispatch, getState) => {
      dispatch(
        this.getOrCreateActiveTrolleyId({
          onDone: (trolley_id) => {
            // we rely on the api to set the customer automatically when authenticated
            const trolley = this.getTrolley();
            if (!trolley.customer) {
              dispatch(this.saveObject({ id: trolley_id })).then(() => {
                dispatch(this.ensureTrolleyLoaded());
              });
            }
          },
        }),
      );
    };
  }

  createTrolley({ gallery }) {
    return trolleyList.saveNewObject({ gallery: get(gallery, "id") });
  }

  setActiveTrolleyId(trolley_id) {
    setActiveTrolleyCookieId(trolley_id);
  }

  _clearActiveTrolleyId() {
    clearActiveTrolleyCookieId();
  }

  _getActiveTrolleyId() {
    return getActiveTrolleyCookieId();
  }
}

export const trolleyList = new TrolleyList("trolley_default");
