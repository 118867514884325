import get from "lodash/get";
import { Button, makeStyles } from "@material-ui/core";
import {
  CROSS_CURRENCY_ERROR,
  CROSS_GALLERY_TYPE_ERROR,
  trolleyPhotographList,
} from "../v2_actions/trolley_photograph";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CheckoutButton } from "./CheckoutButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingSpinner from "../views/components/LoadingSpinner";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { trolleyList } from "../v2_actions/trolley";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  enabledBtn: {
    cursor: "pointer",
  },
  disabledBtn: {
    color: "#fff",
    borderRadius: "50px",
    background: "#a8a8a8",
  },
  photographIsInTrolley: {
    color: "red",
  },
});

export const AddToTrolleyButton = ({ pid, gallery, variant = "text" }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    tryingToAddCrossPhotographerTrolleyPhotograph,
    setTryingToAddCrossPhotographerTrolleyPhotograph,
  ] = useState(null);
  const [
    tryingToAddCrossGalleryTypeTrolleyPhotograph,
    setTryingToAddCrossGalleryTypeTrolleyPhotograph,
  ] = useState(null);
  const [isResettingTrolley, setIsResettingTrolley] = useState(false);
  const trolley = useSelector(() => trolleyList.getTrolley());
  const isTrolleyLoading = useSelector(() => trolleyList.isLoading());
  const selected = useSelector(() =>
    trolleyPhotographList.isPhotographInTrolley(pid, trolley),
  );

  useEffect(() => {
    dispatch(trolleyList.invalidateList());
    dispatch(trolleyList.ensureTrolleyLoaded());
  }, []);

  const onSelectPhotoForBuy = (photograph_id) => {
    if (selected === true) {
      dispatch(trolleyPhotographList.removePhotograph(photograph_id));
    } else {
      const addPhotographToTrolley =
        trolleyPhotographList.canAddPhotographToTrolley(
          gallery,
          trolley,
          photograph_id,
        );

      if (get(addPhotographToTrolley, "can_add")) {
        dispatch(trolleyPhotographList.addPhotograph(photograph_id));
      } else {
        if (
          get(addPhotographToTrolley, "reason") === CROSS_GALLERY_TYPE_ERROR
        ) {
          setTryingToAddCrossGalleryTypeTrolleyPhotograph(photograph_id);
        } else if (
          get(addPhotographToTrolley, "reason") === CROSS_CURRENCY_ERROR
        ) {
          setTryingToAddCrossPhotographerTrolleyPhotograph(photograph_id);
        }
      }
    }
  };

  const onAddPhotographToNewTrolley = () => {
    setIsResettingTrolley(true);
    dispatch(
      trolleyList.resetTrolley(() => {
        if (tryingToAddCrossPhotographerTrolleyPhotograph) {
          dispatch(
            trolleyPhotographList.addPhotograph(
              tryingToAddCrossPhotographerTrolleyPhotograph,
            ),
          ).then(() => {
            setTryingToAddCrossPhotographerTrolleyPhotograph(null);
            setIsResettingTrolley(false);
          });
        } else if (tryingToAddCrossGalleryTypeTrolleyPhotograph) {
          dispatch(
            trolleyPhotographList.addPhotograph(
              tryingToAddCrossGalleryTypeTrolleyPhotograph,
            ),
          ).then(() => {
            setTryingToAddCrossGalleryTypeTrolleyPhotograph(null);
            setIsResettingTrolley(false);
          });
        }
      }),
    );
  };

  const onCheckoutPreviousTrolley = () => {
    navigate("/customer/v2_trolley");
    setTryingToAddCrossPhotographerTrolleyPhotograph(null);
  };

  const tryRenderCrossGalleryTypeWarning = () => {
    return (
      <>
        {isResettingTrolley && <LoadingSpinner />}

        <Dialog
          open={tryingToAddCrossGalleryTypeTrolleyPhotograph}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "You cannot purchase from Photoart and Photos of Me nor multiple Photo of Me galleries with one purchase."
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would you like to checkout with your original photos OR start a
              new shopping cart
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCheckoutPreviousTrolley} autoFocus>
              Checkout
            </Button>
            <Button onClick={onAddPhotographToNewTrolley}>New cart</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const tryRenderCrossPhotographerWarning = () => {
    return (
      <>
        {isResettingTrolley && <LoadingSpinner />}

        <Dialog
          open={tryingToAddCrossPhotographerTrolleyPhotograph}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {
              "You cannot purchase from two different countries with one purchase."
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would you like to checkout with your original photos OR start a
              new shopping cart
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CheckoutButton variant="dialog_checkout_button_photo_art" />
            <Button onClick={onAddPhotographToNewTrolley}>New cart</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const renderTextAddToTrolleyButton = () => {
    if (!selected) {
      return (
        <Button
          variant="text"
          onClick={() => onSelectPhotoForBuy(pid)}
          endIcon={<ShoppingCartIcon />}
        >
          Add to trolley
        </Button>
      );
    } else {
      return (
        <Button
          variant="text"
          onClick={() => onSelectPhotoForBuy(pid)}
          endIcon={<ShoppingCartIcon />}
        >
          Remove from trolley
        </Button>
      );
    }
  };

  const renderIconAddToTrolleyButton = () => {
    if (!selected) {
      return (
        <>
          <ShoppingCartIcon onClick={() => onSelectPhotoForBuy(pid)} />
        </>
      );
    } else {
      return (
        <>
          <ShoppingCartIcon
            className={classes.photographIsInTrolley}
            onClick={() => onSelectPhotoForBuy(pid)}
          />
        </>
      );
    }
  };

  return (
    <>
      {variant === "text" && renderTextAddToTrolleyButton()}
      {tryRenderCrossPhotographerWarning()}
      {tryRenderCrossGalleryTypeWarning()}
      {variant !== "text" && renderIconAddToTrolleyButton()}
    </>
  );
};
