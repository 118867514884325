import get from "lodash/get";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputField } from "../../components/form/InputField";
import { useIsMobile } from "../../actions/ui";
import {
  Container,
  Box,
  Grid,
  Typography,
  makeStyles,
  FormLabel,
  IconButton,
  InputAdornment,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { signInAction, setForgotEmail } from "../../actions/user";
import { forgotPasswordApi } from "../../apis/userapi";
import LogoImg from "../../assets/logo/black_logo.png";
import style from "./styles";
import { trolleyList } from "../../v2_actions/trolley";

const useStyles = makeStyles(style);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be valid")
    .required("Please enter your email"),
  password: yup.string().required("Please enter your password"),
});

const Login = ({ match }) => {
  const classes = useStyles();
  const formRef = useRef();
  const { trolley_id_from_url } = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const country = get(match, ["params", "country"]);
  const trolley = useSelector(() => trolleyList.ensureTrolleyLoaded());
  const errorMsg = useSelector((state) => state.userReducer.errorMessage);
  const generalInfo = useSelector((state) => state.galleryReducer.generalInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  const [values, setValues] = useState({
    email: "",
    password: "",
    submitted: false,
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const loginFormProps = useForm({
    resolver: yupResolver(loginFormSchema),
  });

  useEffect(() => {
    if (errorMsg.showMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMsg]);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    if (
      loginFormProps.getValues("email") &&
      loginFormProps.getValues("password")
    ) {
      setIsReadyToSubmit(true);
    } else {
      setIsReadyToSubmit(false);
    }
  }, [loginFormProps.watch()]);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const onSubmitLogin = (values) => {
    const sendData = {
      email: values.email,
      password: values.password,
      is_staff: 0,
    };

    dispatch(
      signInAction(
        sendData,
        trolley,
        generalInfo,
        country,
        navigate,
        trolley_id_from_url,
      ),
    );
  };

  const onSubmitForgotPassword = (values) => {
    const sendData = {
      email: values.email,
      password: values.password,
      is_staff: 0,
    };
    dispatch(signInAction(sendData, trolley, generalInfo, country, navigate));
  };

  const handleForgotPassword = () => {
    // setForgotPassword(false)
    const sendData = {
      username: values.first_name,
      email: values.email,
    };
    forgotPasswordApi(sendData).then((res) => {
      if (res.data.status === "success") {
        dispatch(setForgotEmail(values.email));
        navigate(`/customer/setpassword`);
      } else {
        setOpenSnackBar(true);
      }
    });
  };

  const renderLogin = () => {
    return (
      <form onSubmit={loginFormProps.handleSubmit(onSubmitLogin)}>
        <Box mt={!isMobile ? 2 : 1}>
          <Typography className={classes.bigtext}>Customer Login</Typography>
        </Box>
        <Grid mt={!isMobile ? 2 : 1}>
          <Typography className={classes.smalltext}>
            Hi there! Nice to see you again.
          </Typography>
        </Grid>
        <Box mt={!isMobile ? 5 : 2}>
          <Grid container spacing={!isMobile ? 5 : 2}>
            <Grid item md={12} xs={12}>
              <FormLabel className={classes.label}>Email</FormLabel>
              <InputField
                name="email"
                placeholder="Your email"
                formProps={loginFormProps}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormLabel className={classes.label}>Password</FormLabel>

              <InputField
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                formProps={loginFormProps}
                inputProps={{
                  style: {
                    height: 50,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid className={classes.signInButtonWrapper} item md={12} xs={12}>
              {renderLoginButton()}
              <Typography
                className={classes.subtitle}
                onClick={() => setForgotPassword(true)}
              >
                Forgot Password?
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={!isMobile ? 5 : 2}
          display="flex"
          className={classes.createAccountWrapper}
        >
          <Typography className={classes.createAccountText}>
            Don't have an account?
          </Typography>
          <Link to={`/customer/signup`}>
            <Typography className={classes.createAccountLink}>
              Create Account
            </Typography>
          </Link>
        </Box>
      </form>
    );
  };

  const renderLoginButton = () => {
    if (isReadyToSubmit) {
      return (
        <>
          <Button className={classes.button} type="submit">
            <Typography className={classes.loginBtnText}>Login</Typography>
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            className={classes.disabledButton}
            type="submit"
            disabled={true}
          >
            <Typography className={classes.disabledLoginBtnText}>
              Login
            </Typography>
          </Button>
        </>
      );
    }
  };

  const renderForgotPassword = () => {
    return (
      <>
        <Box mt={3}>
          <p className={classes.bigtext}>Forgot Password</p>
        </Box>
        <Box mt={3} mb={5}>
          <p className={classes.smalltext}>Did you forget your password?</p>
        </Box>
        <ValidatorForm ref={formRef} onSubmit={onSubmitForgotPassword}>
          <Grid container spacing={5}>
            <Grid item md={12} xs={12}>
              <FormLabel className={classes.label}>First Name</FormLabel>
              <TextValidator
                fullWidth
                name="first_name"
                placeholder="First Name"
                onChange={handleChange}
                value={values.first_name}
                variant="standard"
                validators={["required"]}
                errorMessages={["this field is required"]}
                InputProps={{
                  style: {
                    height: 50,
                  },
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormLabel className={classes.label}>Email</FormLabel>
              <TextValidator
                fullWidth
                name="email"
                placeholder="Your email"
                onChange={handleChange}
                value={values.email}
                variant="standard"
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "* invalid email"]}
                InputProps={{
                  style: {
                    height: 50,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid className={classes.signInButtonWrapper} item md={12} xs={12}>
            <Button
              className={classes.button}
              onClick={handleForgotPassword}
              style={{ marginTop: 50 }}
            >
              <p className={classes.loginBtnText}>Submit</p>
            </Button>
            <Typography
              className={classes.subtitle}
              onClick={() => setForgotPassword(false)}
            >
              Sign In
            </Typography>
          </Grid>
          <Grid className={classes.spacer}></Grid>
        </ValidatorForm>
      </>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.scrollContainer}
    >
      <Box
        className={
          width > 1440
            ? classes.root
            : isMobile
              ? classes.ipadroot
              : classes.mobileroot
        }
        title="Login"
      >
        <Snackbar
          open={openSnackBar}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={() => setOpenSnackBar(false)}
          style={{ height: `calc(100vh - 300px)` }}
        >
          <Alert onClose={() => setOpenSnackBar(false)} severity="error">
            {errorMsg.showMessage}
          </Alert>
        </Snackbar>
        <Container>
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={!isMobile ? 4 : 12}>
              <Box display="flex" justifyContent="left">
                <img src={LogoImg} alt="/" className={classes.logo_img} />
              </Box>
              {isMobile && (
                <Box display="flex" justifyContent="center">
                  <Typography className={classes.blacktitle}>
                    Go Find Yourself
                  </Typography>
                </Box>
              )}
              {forgotPassword && renderForgotPassword()}
              {!forgotPassword && renderLogin()}
            </Grid>
            <Grid xs={1}></Grid>
            {!isMobile && (
              <Grid item xs={5} style={{ paddingRight: 10 }}>
                <Box className={classes.rightside}>
                  <Typography className={classes.imgtitle}>
                    <i>Find Yourself</i>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Login;
