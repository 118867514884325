import React from "react";
import styles from "./Button.module.css";

type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "className"
> & {
  variant?: "default" | "link";
  fill?: boolean;
};

/**
 * Button
 * @returns
 */
const Button = ({ children, fill, variant, ...props }: ButtonProps) => {
  const classes = [];
  if (variant === "link") {
    classes.push(styles.link);
  } else {
    classes.push(styles.button);
  }

  if (fill) {
    classes.push(styles.buttonfill);
  }

  return (
    <button {...props} className={classes.join(" ")}>
      {children}
    </button>
  );
};

export default Button;
