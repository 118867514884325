import upperCase from "lodash/upperCase";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import map from "lodash/map";
import React, { useState, useRef, useEffect, Suspense } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { guessCountryCode } from "../../utils/detectCountryCode";
import {
  Container,
  Box,
  Grid,
  Typography,
  makeStyles,
  FormLabel,
  InputAdornment,
  IconButton,
  Button,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { countryList } from "../../v2_actions/country";
import { BOSSFOTOS_INFO_WEBSITE } from "../../v2_actions/routes";
import { DEFAULT_COUNTRY_CODE } from "../../utils/detectCountryCode";
import MuiAlert from "@material-ui/lab/Alert";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import LogoImg from "../../assets/logo/black_logo.png";
import style from "./styles";
import { useIsMobile } from "../../actions/ui";
import { css, Global } from "@emotion/react";
import { signUp } from "../../v2_actions/auth";

const ReactFlagsSelect = React.lazy(() => import("react-flags-select"));

const global_styles = css`
  #rfs-btn {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding-left: 0px;
  }
  #rfs-btn span {
    padding-left: 0px;
  }
  .MuiCheckbox-root span svg {
    fill: #0c59cf;
  }
  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 8px;
  }
`;

const useStyles = makeStyles(style);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SignUp = ({ match }) => {
  const classes = useStyles();
  const formRef = useRef();
  const { trolley_id_from_url } = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [check, setCheck] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const allowedCountryOptions = useSelector(() =>
    map(countryList.getAllCountryCodes(), (country_code) =>
      upperCase(country_code),
    ),
  );
  const alphabeticallySortedAllowedCountryOptions = sortBy(
    allowedCountryOptions,
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const activeTrolleyPhotos = useSelector(
    (state) => state.trolleyReducer.activePhotoInfos,
  );
  const generalInfo = useSelector((state) => state.galleryReducer.generalInfo);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_number: "",
    country: "",
    photo: "",
    confirmpassword: "",
  });

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {}, [check, values]);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== values.password) {
        return false;
      }
      return true;
    });

    const lookupCountry = (onGuessed) => {
      guessCountryCode((country_code) => {
        const country = get(
          countryList.findForCountryCode(country_code || DEFAULT_COUNTRY_CODE),
          "id",
        );
        setValues({ ...values, country: country });
      });
    };
  }, []);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const remove_trim_uppercase = (value) => {
    if (value !== null && value !== undefined) {
      const loweCaseValue = value.toLowerCase();
      const remove_space_value = loweCaseValue.split(" ").join("");
      return remove_space_value;
    } else {
      return value;
    }
  };

  const handleSubmit = () => {
    if (
      values.password.toLocaleLowerCase() !==
      values.confirmpassword.toLocaleLowerCase()
    ) {
      setOpenSnackBar(true);
      setErrorMessage("password mismatch");
      return false;
    }

    if (values.country === undefined) {
      setOpenSnackBar(true);
      setErrorMessage("Please select a Country");
      return false;
    }

    const sendData = {
      first_name: values.first_name,
      last_name: values.first_name,
      email: values.email,
      password: values.password,
      country: values.country,
      name_used: remove_trim_uppercase(values.first_name),
      is_staff: 0,
    };

    return dispatch(
      signUp(
        sendData,
        navigate,
        setOpenSnackBar,
        setErrorMessage,
        trolley_id_from_url,
      ),
    );
  };

  const renderSignUpButton = () => {
    if (
      check &&
      values.first_name &&
      values.email &&
      values.country &&
      values.password &&
      values.confirmpassword
    ) {
      return (
        <>
          <Button
            className={classes.button}
            type="submit"
            disabled={values.submitted}
          >
            <p className={classes.registerBtnText}>Create Account</p>
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            className={classes.disabledButton}
            type="submit"
            disabled={true}
          >
            <p className={classes.disabledRegisterBtnText}>Create Account</p>
          </Button>
        </>
      );
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ overflowY: "scroll", height: "100vh" }}
    >
      <Global styles={global_styles} />
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        style={{ height: `calc(100vh - 300px)` }}
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box
        className={width > 1440 ? classes.root : classes.mobileroot}
        title="Sign Up"
      >
        <Container>
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid item xs={!isMobile ? 4 : 12}>
              <Box display="flex" className={classes.createAccountWrapper}>
                <Typography className={classes.createAccountText}>
                  Already have an account?
                </Typography>
                <Link
                  to={
                    trolley_id_from_url
                      ? `/customer/login/${trolley_id_from_url}`
                      : "/customer/login"
                  }
                >
                  <Typography className={classes.createAccountLink}>
                    Login
                  </Typography>
                </Link>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={!isMobile ? "row" : "column"}
                mt={1}
              >
                <Typography className={classes.bigtext}>
                  Create Customer Account
                </Typography>
              </Box>
              <Box>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                  <Grid container spacing={!isMobile ? 3 : 1}>
                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>Name</FormLabel>
                      <TextValidator
                        fullWidth
                        name="first_name"
                        placeholder="Your name"
                        value={values.first_name}
                        onChange={handleChange}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>Country</FormLabel>

                      <Suspense fallback={<div>Loading...</div>}>
                        <ReactFlagsSelect
                          searchable
                          validators={["required"]}
                          searchPlaceholder="Country"
                          countries={alphabeticallySortedAllowedCountryOptions}
                          placeholder="Country where you currently live"
                          errorMessages={[
                            "this field is required",
                            "* invalid country",
                          ]}
                          selected={upperCase(
                            get(
                              countryList.getCountryForId(
                                get(values, ["country"]),
                              ),
                              "country_code",
                            ),
                          )}
                          onSelect={(code) => {
                            const country =
                              countryList.getCountryForCountryCode(code);
                            setValues({ ...values, country: country.id });
                          }}
                        />
                      </Suspense>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>Email</FormLabel>
                      <TextValidator
                        fullWidth
                        name="email"
                        placeholder="Your email address"
                        onChange={handleChange}
                        value={values.email}
                        variant="standard"
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "* invalid email",
                        ]}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>Password</FormLabel>
                      <TextValidator
                        fullWidth
                        name="password"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        InputProps={{
                          style: {
                            height: 50,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormLabel className={classes.label}>
                        Confirm Password
                      </FormLabel>
                      <TextValidator
                        fullWidth
                        name="confirmpassword"
                        placeholder="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        value={values.confirmpassword}
                        onChange={handleChange}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        InputProps={{
                          style: {
                            height: 50,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(e) =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Box
                        className={classes.legalText}
                        display="flex"
                        alignItems="center"
                      >
                        <Checkbox
                          onChange={(e) => setCheck(!check)}
                          iconstyle={{ fill: "white" }}
                        />
                        <p className={classes.legalText}>
                          I agree to the{" "}
                          <a
                            target="_blank"
                            className={classes.link}
                            href={`${BOSSFOTOS_INFO_WEBSITE}/terms/`}
                            rel="noreferrer"
                          >
                            Terms of Services
                          </a>{" "}
                          and <br />
                          <a
                            target="_blank"
                            className={classes.link}
                            href={`${BOSSFOTOS_INFO_WEBSITE}/privacy-policy/`}
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </Box>
                    </Grid>
                    <Grid
                      className={classes.registerButtonWrapper}
                      item
                      md={12}
                      xs={12}
                    >
                      {renderSignUpButton()}
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Box>
            </Grid>
            <Grid item md={1}></Grid>
            {!isMobile && (
              <Grid item xs={5}>
                <Box className={classes.rightside}>
                  <Box mt={5} className={classes.banner}>
                    <img src={LogoImg} className="logo_img" alt="/" />
                    <Typography className={classes.imgtitle}>
                      Find Yourself
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default SignUp;
