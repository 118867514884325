import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import TopBar from "./topbar"
import SideBar from "../../layout/sidebar"
import styles from "./styles"
import { getUserStoredCountryCode } from "../../v2_actions/auth"
import { useIsMobile } from "../../actions/ui"

const useStyles = makeStyles(styles)

const Terms = () => {
  const classes = useStyles()
  const isMobile = useIsMobile()
    const [showSideBar, setShowSideBar] = useState(false)
    
  const country = getUserStoredCountryCode()
  return (
    <div>
      <TopBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <SideBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <div
        className={!showSideBar ? classes.nonMennuWrapper : classes.menuWrapper}
        style={{ paddingTop: !isMobile ? 65 : 0 }}
      >
        <div className={classes.content}>
          <div className={classes.pharagraph}>
            <p>
              <strong>Scope and Consent</strong>
            </p>
            <p>This Privacy Notice describes</p>
            <ul>
              <li>
                the personal information we collect and how we use that
                information
              </li>
              <li>when we might disclose your personal information and</li>
              <li> how we keep and protect your personal information.</li>
            </ul>
            <p>
              It applies to this Site and to any Services where this Privacy
              Notice is referenced.
            </p>
            <p>
              By using our Services and/or registering for an account, you are
              accepting the terms of this Privacy Notice and our Terms of
              Service.
            </p>
            <p>
              {country === "za"
                ? "Cape Town, South Africa is your data controller, and is responsible for the collection, use, disclosure, retention and protection of your personal information under our global privacy standards and applicable national laws. Your data controller may transfer data to other members of our corporate family as described in this Privacy Notice who may process and keep your personal information on servers in the European Union, United States and our data centres in other parts of the world."
                : "Sydney, Australia is your data controller, and is responsible for the collection, use, disclosure, retention and protection of your personal information under our global privacy standards and applicable national laws. Your data controller may transfer data to other members of our corporate family as described in this Privacy Notice who may process and keep your personal information on servers in the European Union, United States and our data centres in other parts of the world."}
            </p>
            <p>
              <strong>What is Personal information?</strong>
            </p>
            <p>
              "Personal information" is information that can be associated with
              a specific person that could be used to identify that specific
              person, either from the data directly or from that data combined
              with other information that we have, or are likely to have, access
              to. Personal information doesn’t include anonymous or aggregated
              information that can no longer be used to identify a specific
              person.
            </p>
            <p>
              <strong>Public Information?</strong>
            </p>
            <p>
              Public information is any information, including personal
              information, you share with a public audience, including personal
              information you publish on our site. Public information is
              available to anyone on or off our Services and can be seen or
              accessed through online search engines, APIs, and offline media,
              such as on TV.
            </p>
            <p>
              <strong>Changes to this Privacy Notice</strong>
            </p>
            <p>
              We can change this Privacy Notice at any time by posting the
              updated terms to the Site. The updated terms automatically take
              effect 30 days after they are posted.
            </p>
            <p>
              <strong>Global Privacy Standards</strong>
            </p>
            <p>
              BossFotos has global privacy standards. We are managing your
              personal information everywhere within our corporate family
              according to European Union privacy standards.
            </p>
            <p>
              <strong>Collection</strong>
            </p>
            <p>We collect information you give us including:</p>
            <ul>
              <li>
                When you register for an account: Information such as your name,
                addresses, telephone numbers, email addresses or user ID (where
                applicable) when you register for an account with us
              </li>
              <li>
                When we verify you or your account: we may collect and process
                information (as permitted by law) to authenticate you or your
                account, or to verify the information that you provided to us
              </li>
              <li>
                When you transact on our Services: such as when you post an ad,
                reply to an ad, information you provide during a transaction or
                other transaction-based content. We may also collect your
                financial information (such as credit card or bank account
                numbers) if you buy a feature from us or are required to pay
                fees to us
              </li>
              <li>
                When you engage with our community: such as when you submit a
                web form or participate in community discussions or chats
              </li>
              <li>
                When you interact with your account: such as updating or adding
                information to your account, adding items to alerts lists and
                saving searches. Sometimes you may also give us your age,
                gender, interests and favourites
              </li>
              <li>
                When you contact us: such as through a web form, chat or when we
                otherwise communicate with each other. We may also record our
                calls with you (if we have your consent to do so)
              </li>
            </ul>
            <p>
              {" "}
              <strong>We collect information automatically including:</strong>
            </p>
            <ul>
              <li>
                Information from the devices you use when interacting with us or
                our Services such as device ID or unique user ID, device type,
                ID for advertising and unique device token
              </li>
              <li>Information about your location such as geo-location</li>
              <li>
                Computer and connection information such as statistics on your
                page views, traffic to and from the sites, referral URL, ad
                data, your IP address, your browsing navigate and your web log
                information
              </li>
            </ul>
            <p>
              <strong>
                We collect information using cookies, web beacons and similar
                technologies including:
              </strong>
            </p>
            <ul>
              <li>
                Information about the pages you view, the links you click and
                other actions you take on our Services, or within our
                advertising or email content.
              </li>
            </ul>
            <p>
              <strong>
                Information we collect from other sources including:
              </strong>
            </p>
            <ul>
              <li>
                Information we get from third parties such as publicly available
                demographic information, publicly available contact information,
                credit check information and information from credit bureaus, as
                allowed by applicable national laws
              </li>
              <li>
                Information from social media sites when you use those sites to
                register, share information or connect to your account. Those
                sites may automatically give us access to some personal
                information they hold about you (e.g., content you viewed,
                content you liked, and information about the advertisements you
                were shown or clicked on, etc.). If you give us access to any
                account with video content, we may share your video viewing
                with, or collect information about your video viewing from,
                third-party social media sites for at least two years or until
                you withdraw consent. You control the personal information we
                have access to through your privacy settings on the social media
                site and the permissions you give us when you grant us access.
                When you grant us access to information held by social media
                sites you agree that we may collect, use and retain it in
                accordance with this Privacy Notice
              </li>
              <li>
                Information other users may provide about you. If another user
                gives us information about you, they must tell you about our
                collection, use, disclose and retention policies before giving
                us the information. They must also get your explicit consent
                before giving us your information
              </li>
            </ul>
            <p>
              <strong>Use and Retention</strong>
            </p>
            <p>
              <strong>
                We use your personal information to provide, improve and
                personalise our Services
              </strong>
            </p>
            <p>Your personal information allows us to:</p>
            <ul>
              <li>
                Provide you with access to and use of our Services as well as
                access to your navigate, internal messages and other features we
                may provide
              </li>
              <li>
                Offer you site content that includes items and services that you
                may like
              </li>
              <li>
                Provide you with credit offers and opportunities on behalf of
                other members of our corporate family and their financial
                institution partners. However, we don’t share financial
                information without your explicit consent
              </li>
              <li>Customise, measure and improve our Services</li>
              <li>
                Provide other services requested by you as described when we
                collect the information
              </li>
              <li>
                To provide you with location-based services (such as
                advertising, search results and other personalised content)
              </li>
            </ul>
            <p>
              <strong>
                We use your personal information to contact you about your
                account:
              </strong>
            </p>
            <p>
              We may contact you via BossFotos My Messages, email, telephone,
              SMS messages or postal mail:
            </p>
            <ul>
              <li>
                {" "}
                To give you customer support about your account such as to
                troubleshoot problems, to collect fees or monies owed to us, or
                to get your opinion through surveys{" "}
              </li>
              <li>
                For other reasons such as to enforce our Terms of Service or
                policies and any other agreement we may have with you
              </li>
            </ul>
            <p>
              <strong>
                We use your personal information to personalise our advertising
                and marketing, including to:
              </strong>
            </p>
            <ul>
              <li>Personalise, measure and improve our advertising</li>
              <li>
                Contact you, either via email, telephone, SMS text messages or
                postal mail to offer you discounts and special promotions and to
                let you know about our Services and those of our corporate
                family (if you have consented to this contact). Message and data
                rates may apply
              </li>
              <li>
                Deliver targeted marketing, service updates and promotional
                offers
              </li>
            </ul>
            <p>
              <strong>
                We use your personal information for legal compliance reasons,
                including to:
              </strong>
            </p>
            <ul>
              <li>
                Prevent, detect, mitigate and investigate fraud, security
                breaches, potentially prohibited or illegal activities
              </li>
              <li>
                Enforce our Privacy Notice, our Terms of Service or other
                policies
              </li>
            </ul>
            <p>
              <strong>Retention of personal information:</strong>
            </p>
            <p>
              If your account is active, we will keep your personal information
              as long as we need it for our operations. We may also keep
              personal information from closed accounts to comply with national
              laws, stop fraud, collect fees owed, assist with any
              investigation, enforce our Terms of Service and take other actions
              under applicable national laws. If we no longer need your personal
              information, we securely dispose of it.
            </p>
            <p>
              <strong>Choice</strong>
            </p>
            <p>
              <strong>
                Marketing Communications. If you don’t want to receive marketing
              </strong>
              communications from us, you can unsubscribe via the link in the
              email you received. Registered users may also unsubscribe via the
              communication preferences options in their profiles.
            </p>
            <p>
              <strong>
                Push Notifications. Upon download of certain Services, you are
              </strong>
              provided the option to opt into receiving push notifications from
              BossFotos on your device and within the application. We send you
              push notifications from time-to-time to (a) inform you of service
              related messages, (b) let you know you have messages from others
              in the BossFotos community and (c) update you about any events or
              promotions that we may be running. If you no longer wish to
              receive notifications from us, you can, where applicable, change
              your preferences by logging into your account or by disabling
              notifications in your device settings. To ensure you receive
              proper notifications, we will need to collect certain information
              about your device such as operating system and user identification
              information.
            </p>
            <p>
              <strong>Access</strong>
            </p>
            <ul>
              <li>
                You can see, review and change your personal information by
                signing in to your account. Please update your personal
                information immediately if it changes or is inaccurate.
              </li>
              <li>
                {" "}
                Once you make a public posting, you may not be able to change or
                remove it. If you ask us to, we will close your account and
                remove your personal information from view as soon as reasonably
                possible, based on your account activity and as required under
                applicable national laws.
              </li>
              <li>
                {" "}
                We will honour your right to request access to, or modification
                or deletion of, your personal information. We may have the right
                to withhold that access or refuse to modify or erase your
                personal information under applicable national laws, but we will
                give you reasons if we do so. To contact us about access and to
                find out if any fees apply, please contact us.{" "}
              </li>
            </ul>
            <p>
              <strong>Disclosure</strong>
            </p>
            <p>
              <strong>
                We disclose your information to our corporate family members,
                who may use it to:
              </strong>
            </p>
            <ul>
              <li>
                provide joint content and services (like registration,
                transactions and customer support)
              </li>
              <li>
                help detect and stop possible fraud and illegal acts, violations
                of our Terms of Service, and data security breaches
              </li>
              <li> offer you personalised advertising</li>
              <li>
                guide decisions about their products, sites, applications,
                services, tools and marketing communications. Other group
                companies will not send you marketing communications unless you
                have consented to receiving their communications
              </li>
            </ul>
            <p>
              <strong>
                We disclose your information to third-party service providers
                and financial institution partners, who may use it to:
              </strong>
            </p>
            <ul>
              <li>
                {" "}
                law enforcement or governmental agencies, or authorised
                third-parties, in response to a verified request regarding a
                criminal investigation, alleged or suspected illegal activity,
                or any other activity that may expose us, you or any of our
                users to legal risk or if we believe in good faith that
                disclosure is necessary for the prevention or detection of
                crime, the capture or prosecution of offenders, or the
                assessment or collection of tax or duty
              </li>
              <li>
                participants of the “Verified Rights Owners Program” under
                confidentiality agreement, if we decide that it is necessary to
                disclose that information in connection with an investigation of
                fraud, intellectual property infringement, piracy or other
                unlawful activity
              </li>
              <li>
                credit agencies or bureaus as authorised by applicable national
                laws
              </li>
              <li>
                {" "}
                third parties commencing, or involved in, a legal proceeding if
                they provide us with a subpoena, court order or similar legal
                document{" "}
              </li>
              <li>
                other third parties to comply with our legal requirements,
                enforce our Terms of Service, respond to claims that a listing
                or other content violates the rights of others, or if we
                otherwise believe in good faith that the disclosure is needed to
                prevent imminent physical harm, harm to property, financial loss
                or to report suspected illegal activity
              </li>
            </ul>
            <p>
              <strong>Change of ownership</strong>
            </p>
            <p>
              We protect your information using technical and administrative
              security measures. Our safeguards include firewalls and data
              encryption, physical access controls to data centres and
              information access authorisation controls. If you believe your
              account has been abused, please contact us.{" "}
            </p>
            <p>
              <strong>Important Information</strong>
            </p>
            <p>
              <strong>Social login</strong>
            </p>
            <p>When you use social login:</p>
            <ul>
              <li>
                {" "}
                If you sign into your social network account using the same
                email that you previously used to register for an account with
                us, you will access your existing account with us.{" "}
              </li>
              <li>
                If you sign into your social network account using an email
                address that we don’t recognise in our systems, a new account
                will be created in our systems for you.{" "}
              </li>
            </ul>
            <p>
              You may use social login or regular sign in each time you login
              into your account with us. If you use regular sign in but a
              password has not been issued to you (because you registered
              through social login) you need to get a password through the
              password reset flow.
            </p>
            <p>
              When you use social login the "keep me signed in" policies of the
              social network provider apply. We do not control those policies.
              For your safety we recommend that you:
            </p>
            <ul>
              <li>
                Don’t use social login if you are accessing our Services using a
                public or shared device
              </li>
              <li>
                Visit your social network provider site to learn about their
                sign in policies and your options
              </li>
            </ul>
            <p>
              <strong>Unwanted or threatening email</strong>
            </p>
            <p>
              We do not tolerate abuse of our Services. You do not have
              permission to add other users to your mailing list, call or send
              SMS messages for commercial purposes, even if a user bought
              something from you, unless the user has given their explicit
              consent. Sending unwanted or threatening email and SMS messages is
              against our Terms of Service. To report spam or spoof emails
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms
