import {
  Button,
  DropDown,
  H2,
  Shell,
  ViewPort,
} from "../../design-system/designsystem";
import { CircularProgress, Snackbar } from "@material-ui/core";
import { Trolley, TrolleyPhotograph } from "../../types/backend";
import {
  getLoggedInUserEmail,
  loggedInUserLastName,
} from "../../v2_actions/auth";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AlertDialog from "../../v2_components/AlertDialog";
import { CurrencyValue } from "../../v2_components/CurrencyValue";
import type { DropDownOption } from "../../design-system/designsystem";
import MuiAlert from "@material-ui/lab/Alert";
import { NavBar } from "../trolley/NavBar";
import RevolutCheckout from "@revolut/checkout";
import { SimplifyPaymentGateway } from "../../utils/SimplifyPaymentGateway";
import { authenticatedTrolleyList } from "../../v2_actions/authenticated_trolley";
import { basePhotographList } from "../../v2_actions/customer_photograph";
import { countryList } from "../../v2_actions/country";
import { green } from "@material-ui/core/colors";
import { http } from "../../lib";
import { makeLogger } from "../../utils/o11y/logger";
import { photographerList } from "../../v2_actions/photographer";
import { revolutTrolley } from "../../v2_actions/revolut_trolley";
import { setMessage } from "../../actions/trolley";
import styles from "./TrolleyScreen.module.css";
import { trolleyList } from "../../v2_actions/trolley";
import { trolleyPhotographList } from "../../v2_actions/trolley_photograph";
import { trolleyProcessPayment } from "../../v2_actions/trolley_process_payment";
import { useReduxStateReconcilation } from "../../v2_components/hooks/useReduxStateReconcilation";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "../../hooks/useThunkDispatch";
import useTrolleyState from "../../hooks/useTrolleyState";
import { sentryApi } from "../../utils/o11y/sentryApi";

const logger = makeLogger({
  enabled: true,
  label: "TrolleyScreen",
  color: "orange",
});

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// @ts-ignore
const yoco = new window.YocoSDK({
  // @ts-ignore
  publicKey: window.LOCAL_SETTINGS.YOCO_PUBLIC_KEY,
});

const qualityOptions = (photograph: TrolleyPhotograph) => {
  const options: DropDownOption[] = [];
  if (photograph.base_price !== null) {
    options.push({
      value: "high-res",
      label: "High Res Digital Copy",
    });
  }
  if (photograph.low_res_price !== null) {
    options.push({
      value: "low-res",
      label: "Low Res Digital Copy",
    });
  }
  return options;
};

const TrolleyScreen = () => {
  useReduxStateReconcilation();
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const { trolley_id: trolleyIdFromUrl } = useParams();
  const trolley = trolleyList.getTrolley() as Trolley;

  logger.log("redirector", { trolleyIdFromUrl }, trolley?.id);
  useEffect(() => {
    if (!trolleyIdFromUrl || !trolley?.id) {
      // TODO: Bug in the ensureTrolleyLoaded code where
      // save trolley gets called with ids=undefined which
      // in turn returns nothing from the server.
      //
      // Current live behaviour relies on "add to cart" persisting
      // the trolleyid returned in state in order for local UX to
      // redirect to it, which is persisted in local storage (crap)
      logger.log("redirector => load ids");
      dispatch(trolleyList.ensureTrolleyLoaded());
      dispatch(authenticatedTrolleyList.ensureTrolleyLoaded());
    }

    if (trolley?.id && trolleyIdFromUrl === undefined) {
      navigate(`/customer/shopping/${trolley?.id}`);
    }
    //}
  }, [trolleyIdFromUrl, trolley?.id]);

  const handleHomeClicked = () => {
    navigate("/customer/events/");
  };

  if (!trolley?.id || !trolleyIdFromUrl) {
    return (
      <ViewPort>
        <Shell>
          <NavBar onHomeClicked={handleHomeClicked} />

          <div className={styles.cartcontainer}>
            <div className={styles.cartcontainerinner}>
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: 60,
                  height: 60,
                  color: green[500],
                  zIndex: 999,
                }}
              />
            </div>
          </div>
        </Shell>
      </ViewPort>
    );
  }

  if (trolley?.id && trolley?.is_purchased) {
    return (
      <ViewPort>
        <Shell>
          <NavBar onHomeClicked={handleHomeClicked} />

          <div className={styles.cartcontainer}>
            <div className={styles.cartcontainerinner}>
              <H2>Your trolley has expired</H2>
              <p>Please can you close your browser and start again</p>
            </div>
          </div>
        </Shell>
      </ViewPort>
    );
  }

  return <TrolleyScreenInner />;
};

const TrolleyScreenInner = () => {
  useReduxStateReconcilation();
  // @ts-ignore
  const message = useSelector((state) => state.trolleyReducer.message);
  const dispatch = useThunkDispatch();
  const navigate = useNavigate();
  const trolleyState = useTrolleyState();
  const { trolley_id: trolleyIdFromUrl } = useParams();
  const [params] = useSearchParams();
  const cartreturn = params.get("cartreturn");
  const [disableCheckoutButton, setDisableCheckoutButton] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPostPurchaseRedirectMessage, setShowPostPurchaseRedirectMessage] =
    useState(false);
  const [showingSimplifyPaymentGateway, setShowingSimplifyPaymentGateway] =
    useState(false);

  const galleryCurrencyCode = countryList.getCurrencyCode(
    trolleyState.trolley?.country,
  );
  const indexedGalleryId = basePhotographList.getFilter()?.indexed_gallery_id;

  const facialRecognitionGalleryIdMatchesGalleryId =
    indexedGalleryId === trolleyState.trolley?.gallery;

  useEffect(() => {
    if (trolleyIdFromUrl) {
      authenticatedTrolleyList.setActiveTrolleyId(trolleyIdFromUrl);
    } else {
      dispatch(authenticatedTrolleyList.ensureTrolleyLoaded());
    }
  }, [trolleyIdFromUrl]);

  useEffect(() => {
    dispatch(photographerList.loadAllPhotographers());
    dispatch(authenticatedTrolleyList.invalidateObject(trolleyIdFromUrl));
    dispatch(authenticatedTrolleyList.updatePaginationNumItemsPerPage(1));
    dispatch(authenticatedTrolleyList.ensureObjectLoaded(trolleyIdFromUrl));
    dispatch(authenticatedTrolleyList.fetchListIfNeeded());
  }, []);

  useEffect(() => {
    dispatch(trolleyList.ensureTrolleyLoaded());
    if (facialRecognitionGalleryIdMatchesGalleryId) {
      dispatch(basePhotographList.clearListFilter());
    }
  }, []);

  const redirectBackFromTrolley = async () => {
    logger.log("redirectBackFromTrolley", cartreturn, trolleyState.trolley);
    if (cartreturn) {
      navigate(cartreturn);
      return;
    }

    const trolley_photographs = trolleyState.trolley?.trolley_photographs ?? [];

    if (trolley_photographs.length > 0) {
      const last = trolley_photographs[trolley_photographs.length - 1];
      const galleryId = last.photograph.gallery;
      const [response] = await http.get(`customer/gallery/${galleryId}/`);
      logger.log("redirectBackFromTrolley", galleryId, response);

      if (response.db_id) {
        navigate(
          `/customer/gallery/${(response.gallery_album_name ?? response.session_name).replace(/\s/g, "-")}/${response.db_id}`,
        );
        return;
      }
    }

    // empty cart, can't detect
    navigate("/customer/events");
  };

  const handleBackClicked = () => {
    redirectBackFromTrolley();
  };

  const handleContinueShoppingClick = async () => {
    redirectBackFromTrolley();
  };

  const handleHomeClicked = () => {
    navigate("/customer/events/");
  };

  const handleRemovePhoto = (trolleyPhoto: TrolleyPhotograph) => {
    // not working because trolley_photograph uses non
    // authenticated list
    dispatch(
      trolleyPhotographList.removePhotograph(trolleyPhoto.photograph.id),
    );
  };

  const handleQualityChanged = async (
    photograph: TrolleyPhotograph,
    value: string,
  ) => {
    const payload = {
      copy_to_remaining_photographs: photograph.copy_to_remaining_photographs,
      country_print_price: photograph.country_print_price,
      id: photograph.id,
      photo_delivery_type: photograph.photo_delivery_type,
      quality: value,
      trolley: photograph.trolley,
      trolley_photograph_form: true,
    };

    logger.log("change photo quality", payload);

    const res = await dispatch(trolleyPhotographList.saveObject(payload));

    logger.log("change photo quality res=>", res);

    dispatch(trolleyList.invalidateObject(trolleyState.trolley?.id));
    dispatch(trolleyList.ensureObjectLoaded(trolleyState.trolley?.id));
    dispatch(
      authenticatedTrolleyList.invalidateObject(trolleyState.trolley?.id),
    );
    dispatch(
      authenticatedTrolleyList.ensureObjectLoaded(trolleyState.trolley?.id),
    );
  };

  logger.log(trolleyState, { cartreturn });

  const onMessage = (msg: string) => {
    dispatch(setMessage(msg));
    if (msg) {
      setShowSpinner(false);
      setOpenSnackBar(true);
    }
  };

  const createFakePayment = (total_price: number) => {
    setShowPostPurchaseRedirectMessage(true);
    dispatch(
      trolleyProcessPayment.pay({
        trolley_id: trolleyState.trolley?.id,
        gateway_payment_ref: null,
      }),
      // @ts-ignore
    ).then(onPaymentSuccessfulAndConfirmed);
  };

  const onPaymentSuccessfulAndConfirmed = () => {
    logger.log("onPaymentSuccessfulAndConfirmed");
    setShowSpinner(false);
    dispatch(authenticatedTrolleyList.resetTrolley());
    navigate("/customer/purchased");
  };

  const checkOutYoco = async () => {
    if (trolleyState.totalPriceOwingByCustomer <= 2) {
      createFakePayment(trolleyState.totalPriceOwingByCustomer);
    } else {
      // @ts-ignore
      yoco.showPopup({
        amountInCents: trolleyState.totalPriceOwingByCustomerInCents,
        currency: "ZAR",
        name: "Check Out Trolley",
        description: "Check Out Trolley",
        callback: function (result: any) {
          if (result.error) {
            setOpenSnackBar(true);
            sentryApi.error("yoco_checkout_error", result.error);
            onMessage(result.error.message);
          } else {
            setShowSpinner(true);
            const payment_token = result.id;
            dispatch(
              trolleyProcessPayment.pay({
                trolley_id: trolleyState.trolley?.id,
                gateway_payment_ref: payment_token,
              }),
              // @ts-ignore
            ).then(onPaymentSuccessfulAndConfirmed);
          }
        },
      });
    }
  };

  const checkOutSimplify = async () => {
    if (trolleyState.totalPriceOwingByCustomer <= 2) {
      createFakePayment(trolleyState.totalPriceOwingByCustomer);
    } else {
      setShowingSimplifyPaymentGateway(true);
    }
  };

  const checkOutRevolut = () => {
    if (trolleyState.totalPriceOwingByCustomer <= 2) {
      createFakePayment(trolleyState.totalPriceOwingByCustomer);
    } else {
      const payload = {
        amount: trolleyState.totalPriceOwingByCustomerInCents,
        currency: galleryCurrencyCode.toUpperCase(),
      };

      dispatch(
        revolutTrolley.prepareOrder({ trolley_id: trolleyState.trolley?.id }),
      )
        // @ts-ignore
        .then((res) => {
          const public_id = res.public_id;
          // @ts-ignore
          RevolutCheckout(public_id, window.LOCAL_SETTINGS.REVOLUT_MODE).then(
            (instance) => {
              instance.payWithPopup({
                name: loggedInUserLastName(),
                email: getLoggedInUserEmail(),

                onSuccess() {
                  const payment_token = public_id;
                  dispatch(
                    trolleyProcessPayment.pay({
                      trolley_id: trolleyState.trolley?.id,
                      gateway_payment_ref: payment_token,
                    }),
                    // @ts-ignore
                  ).then(onPaymentSuccessfulAndConfirmed);
                },
                onError(message) {
                  setOpenSnackBar(true);
                  console.error(message);
                  sentryApi.error("revolut_checkout_error_response", message);
                  onMessage("The payment processing failed");
                },
                onCancel() {
                  setOpenSnackBar(true);
                  onMessage("Payment cancelled!");
                },
              });
            },
          );
        })
        .catch((err: any) => {
          console.error(err);
          sentryApi.error("revolut_checkout_error", err);
          setOpenSnackBar(true);
          onMessage("Payment preparation failed");
        });
    }
  };

  const handleCheckoutClick = async () => {
    setDisableCheckoutButton(true);
    if (
      trolleyState.trolley?.id &&
      trolleyState.totalPriceOwingByCustomer >= 0
    ) {
      logger.log(
        "handleCheckoutClick",
        trolleyState.paymentGateway,
        trolleyState.trolley?.id,
      );
      switch (trolleyState.paymentGateway) {
        case "yoco":
          checkOutYoco();
          break;
        case "simplify":
          checkOutSimplify();
          break;
        case "revolut":
          checkOutRevolut();
          break;
        default:
          sentryApi.error("trolley_no_payment_gateway", {
            paymentGateway: trolleyState?.paymentGateway,
            ...trolleyState?.trolley,
          });
          console.error(
            `Unknown payment gateway: ${trolleyState.paymentGateway}`,
          );
          onMessage(
            "Payment preparation failed, couldn't find payment gateway",
          );
          break;
      }
    } else {
      navigate(`/customer/events`);
    }
  };

  return (
    <ViewPort>
      <Shell>
        <Snackbar
          open={openSnackBar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert onClose={() => setOpenSnackBar(false)} severity="error">
            {message}
          </Alert>
        </Snackbar>

        <NavBar
          onBackClicked={handleBackClicked}
          onHomeClicked={handleHomeClicked}
        />

        <div className={styles.cartcontainer}>
          <div className={styles.cartcontainerinner}>
            <div className={styles.cartcontaineritems}>
              {(trolleyState.trolley?.trolley_photographs?.length ?? 0) ===
                0 && (
                <div className={styles.centered}>
                  <H2>Empty Cart</H2>
                  <p>Add photos to purchase</p>
                </div>
              )}
              {trolleyState.trolley?.trolley_photographs?.map((photograph) => (
                <>
                  <div
                    className={styles.cartitem}
                    key={`trolley-cartitem-${photograph.id}`}
                  >
                    <div className={styles.cartitemthumb}>
                      <img
                        src={
                          photograph.photograph.gallery_size_file_info
                            .download_url
                        }
                      />
                    </div>
                    <div className={styles.cartitemsummary}>
                      <div className={styles.microsummaryitem}>
                        <div className={styles.microsummaryitemdesc}>
                          <DropDown
                            disabled={trolleyState.isUpdating}
                            onSelected={(option) =>
                              handleQualityChanged(photograph, option.value)
                            }
                            selectedValue={photograph.quality}
                            renderDropDownOption={(option) => {
                              return (
                                <>
                                  {option.label}{" "}
                                  <b>
                                    <CurrencyValue
                                      country={
                                        trolleyState.photographer.country
                                      }
                                      value={
                                        option.value === "low-res"
                                          ? photograph.low_res_price
                                          : photograph.base_price
                                      }
                                      horizontal_spacing={false}
                                    />
                                  </b>
                                </>
                              );
                            }}
                            options={qualityOptions(photograph)}
                          />
                        </div>
                        <div className={styles.microsummaryitemaction}>
                          <div className={styles.cartprice}>
                            <CurrencyValue
                              country={trolleyState.photographer.country}
                              value={
                                photograph.quality === "high-res"
                                  ? photograph.base_price
                                  : photograph.low_res_price
                              }
                              horizontal_spacing={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.microsummaryitem}>
                        <div className={styles.microsummaryitemdesc}>
                          <Button
                            variant="link"
                            onClick={() => handleRemovePhoto(photograph)}
                          >
                            Remove
                          </Button>
                        </div>
                        <div className={styles.microsummaryitemaction}></div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div className={styles.cartcontainersummary}>
              <H2>Summary</H2>
              <div className={styles.cartsummaryline}>
                <div>
                  Total Items - {trolleyState.trolley?.number_of_photos}
                </div>
                <div>
                  <CurrencyValue
                    country={trolleyState.trolley?.country}
                    value={trolleyState.trolley?.total_photograph_price}
                    horizontal_spacing={false}
                  />
                </div>
              </div>
              <div className={styles.cartsummaryline}>
                <div>Bulk Discounts</div>
                <div>
                  <CurrencyValue
                    country={trolleyState.trolley?.country}
                    value={trolleyState.trolley?.bulk_discount}
                    horizontal_spacing={false}
                  />
                </div>
              </div>
              <div className={styles.cartsummaryline}>
                <div>Admin Fee</div>
                <div>
                  <CurrencyValue
                    country={trolleyState.trolley?.country}
                    value={trolleyState.trolley?.admin_charge}
                    horizontal_spacing={false}
                  />
                </div>
              </div>
              <hr />
              <div
                className={
                  styles.cartsummaryline + " " + styles.cartsummarylineprimary
                }
              >
                <div>
                  <b>
                    Total Price in{" "}
                    {trolleyState.photographerCountry?.currency_name}
                  </b>
                </div>
                <div>
                  <CurrencyValue
                    country={trolleyState.trolley?.country}
                    value={trolleyState.trolley?.total_price_owing_by_customer}
                    horizontal_spacing={false}
                  />
                </div>
              </div>

              <Button
                fill={true}
                disabled={disableCheckoutButton || trolleyState.isUpdating}
                onClick={handleCheckoutClick}
              >
                Checkout
              </Button>
              <Button
                variant={"link"}
                fill={true}
                onClick={handleContinueShoppingClick}
              >
                Continue Shopping
              </Button>
            </div>
          </div>
        </div>
      </Shell>
      {(showSpinner || trolleyState.isLoading) && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: 60,
            height: 60,
            color: green[500],
            zIndex: 999,
          }}
        />
      )}
      {showingSimplifyPaymentGateway && (
        <SimplifyPaymentGateway
          onClose={() => setShowingSimplifyPaymentGateway(false)}
          trolley={trolleyState.trolley}
          onPaymentSuccessfulAndConfirmed={onPaymentSuccessfulAndConfirmed}
          onPaymentFailure={(msg: string) => {
            setShowingSimplifyPaymentGateway(false);
            setShowSpinner(false);
            onMessage(msg);
          }}
          showSpinner={setShowSpinner}
        />
      )}
      {showPostPurchaseRedirectMessage && (
        <AlertDialog
          allowClose={false}
          disablePortal={false}
          openAlertDialog={showPostPurchaseRedirectMessage}
          setOpenAlertDialog={setShowPostPurchaseRedirectMessage}
          heading="Thank you for your purchase."
          body="Please be patient. We are creating your photos and will redirect you. Please don't click on anything while this happens. Thanks"
          actions={undefined}
        />
      )}
    </ViewPort>
  );
};

export default TrolleyScreen;
