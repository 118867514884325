import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";
import get from "lodash/get";
import getSymbolFromCurrency from "currency-symbol-map";
import { getLocation } from "../apis";
import { setHasCachedPosition } from "../v2_actions/map";

const publicIp = require("public-ip");

const defaultCountryLocations = {
  za: { lat: -33.918861, lng: 18.4233 },
  au: { lat: -25.865143, lng: 151.2099 },
  us: { lat: 51.509865, lng: -0.118092 }, // london for some reason
  ca: { lat: 43.659, lng: -79.4027 },
};

export const DEFAULT_COUNTRY_CODE = "ca";

const VALID_COUNTRY_CODES = ["za", "au", "us", "ca"];
const URLS_WITHOUT_COUNTRY_CODE = ["corporate"];
const HOME_URL = "/customer/events";

const getCountryCoords = (code) => {
  return (
    defaultCountryLocations[code] ??
    defaultCountryLocations[DEFAULT_COUNTRY_CODE]
  );
};

export const ensureCountryCodeIsValid = () => {
  guessCountryCode(async ({ country_code, position }) => {
    const { currency_code, currency_symbol } =
      await getCountryDetails(country_code);
    storeCountryDetails({
      country_code,
      currency_code,
      currency_symbol,
      position,
    });
  });
};

export const guessCountryCode = (onGuessed) => {
  publicIp
    .v4()
    .then((ip_address) => {
      getLocation(ip_address)
        .then((response) => {
          const data = response.data;
          let country_code = lowerCase(data.countryCode);
          const pos_array = data.location;
          const position = { lat: pos_array[0], lng: pos_array[1] };
          onGuessed({ country_code, position });
        })
        .catch((data, status) => {
          onGuessed({
            country_code: DEFAULT_COUNTRY_CODE,
            position: getCountryCoords(defaultCountryLocations),
          });
        });
    })
    .catch((data, status) => {
      onGuessed({
        country_code: DEFAULT_COUNTRY_CODE,
        position: getCountryCoords(defaultCountryLocations),
      });
    });
};

let currencies;

export const getCountryDetails = async (country_code) => {
  if (!currencies) {
    currencies = await import("./currencies.json");
  }

  const currency_code = get(currencies, [
    upperCase(country_code),
    "currencies",
    0,
  ]);
  return {
    currency_code,
    currency_symbol: getSymbolFromCurrency(currency_code),
    position: getCountryCoords(defaultCountryLocations),
  };
};

const storeCountryDetails = ({
  country_code,
  currency_code,
  currency_symbol,
  position,
}) => {
  window.localStorage.setItem("country", country_code);
  window.localStorage.setItem("currency_code", currency_code);
  window.localStorage.setItem("currency", currency_symbol);
  if (position) {
    window.localStorage.setItem("lat", position.lat);
    window.localStorage.setItem("lng", position.lng);
    setHasCachedPosition();
  }
};

export const countrycodeToNameConverter = (name) => {
  let countryName = new Intl.DisplayNames(["en"], { type: "region" });
  return countryName.of(name);
};
