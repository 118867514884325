import cookie from "react-cookies";
import { isWindowInIframe } from "./nav";
import { sentryApi } from "../utils/o11y/sentryApi";

// Note: These functions are isolated to assist with imports, generally you shouldn't be calling these, use the functions on trolley/trolleyList instead

window.__fallbackToGlobal = false;
window.__trolleyId = undefined;

export const getActiveTrolleyCookieId = () => {
  if (window.__fallbackToGlobal) {
    return window.__trolleyId;
  }

  return cookie.load("trolley_id", { path: "/" });
};

export const setActiveTrolleyCookieId = (trolley_id) => {
  sentryApi.breadcrumb({
    category: "trolley",
    level: "info",
    message: "setActiveTrolleyCookieId",
    data: { trolley_id },
  });

  if (trolley_id && trolley_id.includes("nvalid")) {
    sentryApi.error("trolley_id_invalid", { trolley_id });
  }

  if (window.__fallbackToGlobal) {
    window.__trolleyId = trolley_id;
  }

  clearActiveTrolleyCookieId();

  try {
    if (isWindowInIframe) {
      document.cookie = `trolley_id=${trolley_id};expires=0;path=/;SameSite=None; Secure;`;
    } else {
      cookie.save("trolley_id", trolley_id, { path: "/", expires: 0 });
    }
  } catch (e) {
    sentryApi.error("trolley_id_cookie_failure", { trolley_id });
  }

  const retrievedValue = getActiveTrolleyCookieId();

  if (retrievedValue !== trolley_id) {
    window.__trolleyId = true;
  }
};

export const clearActiveTrolleyCookieId = () => {
  cookie.remove("trolley_id", { path: "/" });
  cookie.remove("trolley_id", { path: "/customer" });
};

// Tactical fix to try and prevent trolley cookies writing at this path -
//  it is not apparent from the codebase what might be causing this
export const clearSubPathTrolleyCookieId = () => {
  cookie.remove("trolley_id", { path: "/customer" });
};
