import { ItemList } from "../orm";
import { purchasedTrolleyList } from "./purchased_trolley";

class PurchasedTrolleyPhotographList extends ItemList {
  getEntityKey() {
    return "customer/purchased_trolley_photograph";
  }

  onDownloaded(purchased_trolley_photograph_id) {
    return async (dispatch, getState) => {
      dispatch(
        this.saveObject({
          id: purchased_trolley_photograph_id,
          action: "downloaded",
        }),
      ).then((res) => {
        dispatch(purchasedTrolleyList.invalidateObject(res.purchased_trolley));
        dispatch(
          purchasedTrolleyList.ensureObjectLoaded(res.purchased_trolley),
        );
      });
    };
  }
}

export const purchasedTrolleyPhotographList =
  new PurchasedTrolleyPhotographList("purchased_trolley_photograph_default");
